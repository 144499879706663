import { useViewport } from "../../hooks/useResponsive/useResponsive";
import { SignInRegisterDesktop } from "./SignInRegisterDesktop";
import { SignInRegisterMobile } from "./SignInRegisterMobile";

export const widthBreakPointToRegister = 769;

export function SignInRegister() {
  const { width } = useViewport();

  return (
    width < widthBreakPointToRegister ? <SignInRegisterMobile /> : <SignInRegisterDesktop />
  );
}

export default SignInRegister;