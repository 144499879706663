import { Helmet } from "react-helmet-async";
import { useRef } from "react";

export default function ReclameAquiBadge() {
  const ref = useRef<HTMLDivElement>(null);
  const hasLoaded = Boolean(ref.current?.innerHTML);

  return (
    <>
      {!hasLoaded ? (
        <Helmet>
          <script
            type="text/javascript"
            id="ra-embed-reputation"
            src="https://s3.amazonaws.com/raichu-beta/selos/bundle.js"
            data-id="bU9ldnd4dUd0MF84anRqcDpxdWVyby0yLWluZ3Jlc3Nvcw=="
            data-target="reputation-ra"
            data-model="1"
          ></script>
        </Helmet>
      ) : null}

      <div id="reputation-ra" ref={ref}></div>
    </>
  );
}
