import moment from "moment";
import { useEffect, useState } from "react";


export const useCounterToTime = () => {
  const [counter, setCounter] = useState<string>('');
  const [timerInSeconds, setTimerInSeconds] = useState<number>(); 
  const [finalTime, setFinalTime] = useState<Date>();
  const [timeRunning, setTimeRunning] = useState<boolean>();

  function zeroPad(num:number, places:number) {
    var zero = places - num.toString().length + 1;
    return Array(+(zero > 0 && zero)).join("0") + num;
  }

  const startTimer = () => {
    if(finalTime){

      const now = moment();
      const finalTimeMoment = moment(finalTime);
      
      const minutes = finalTimeMoment.diff(now, 'minutes');
      const timeInSeconds = finalTimeMoment.diff(now, 'seconds');

      const seconds = Math.round(((timeInSeconds / 60 ) - minutes ) * 60);
      
      setCounter(`${zeroPad(minutes, 2)}:${zeroPad(seconds, 2)}`);
      setTimerInSeconds(timeInSeconds);
      
      if (minutes <= 0 && seconds <= 0) {
        setTimeRunning(false)
      } else {
        setTimeRunning(true)
      }
    }
  };

  useEffect(() => {
    let timeoutId = setTimeout(() => {
      startTimer();
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [counter, finalTime]);

  return { timer: counter, setFinalTime, timeRunning, timerInSeconds };
};
