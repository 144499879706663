import styled from "styled-components";

export const WrapperContent = styled.div`
    margin-top: 7.2rem;
    margin-bottom: 10rem;

    @include responsive(1600px) {
    margin-bottom: 7rem;
    }

    @include responsive(475px) {
    margin-bottom: 0;
    }
`;