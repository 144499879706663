import React from "react";
import { ButtonStyle } from "./styles";
import { StyledButtonProps } from "./interface";

export const Button: React.FC<StyledButtonProps> = ({
  children,
  disabled,
  type = "button",
  onClick,
  ButtonColor,
  className,
  style,
  ButtonSize,
  customDisabled
}: StyledButtonProps) => {
  return (
    <ButtonStyle
      type={type}
      onClick={onClick}
      disabled={disabled}
      ButtonColor={ButtonColor}
      className={className}
      style={style}
      ButtonSize={ButtonSize}
      customDisabled={customDisabled}
    >
      {children}
    </ButtonStyle>
  );
};
