import {  useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { useCart } from "hooks/useCart/useCart";
import { useAuth } from "../../hooks/useAuthProvider/useAuth";

import CommissionerIdentification from "./components/CommissionerIdentification";
import ModalPaymentDetail from "components/ModalPaymentDetail";
import HeaderLogo from "layouts/Header/components/HeaderLogo";
import { SignInButton } from "components/SignInButton";
import { Modal } from "components/Modal";

import { 
  CartButton, 
  CartButtonMobile, 
  CartButtonWrapper, 
  CartClock, 
  CartImg, 
  Divide, 
  HeaderLink, 
  HeaderLinkA, 
  HeaderSection, 
  IdentificationWrapper, 
  LogoutButton, 
  LogoutButtonImg, 
  LogoutButtonText, 
  LogoutButtonWrapper, 
  MenuImage, 
  MenuSection, 
  ModalPaymentWrapper, 
  ModalSair, 
  OldIosFix, 
  ShowModal, 
  ToggleMenu 
} from "./styles";
import { useEventsData } from "hooks/useEventsData/useEventsData";

const ComissionerHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const refHeader = useRef<HTMLElement>(null);
  const refPaymentModal = useRef<HTMLDivElement>(null);
  const [showModalPayment, setShowModalPayment] = useState(false);
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const { showLogo } = useEventsData();

  const { quantityItemInCart } = useCart();
  const { isAuthenticated, logout } = useAuth();

  const handleOpenMenuMobile = () => {
    document.documentElement.classList.add("is-menu-opened");
  };
  const addShadow = () => {
    if (window.pageYOffset < 80) {
      refHeader.current?.classList.remove("is-active-shadow");
    }

    if (window.pageYOffset > 80) {
      refHeader.current?.classList.add("is-active-shadow");
    }
  };

  const paymentModalHandler = () => {
    if(quantityItemInCart){
      setShowModalPayment(!showModalPayment);
    }else{
      setShowModalPayment(false);
    }
  }

  const handleOpenModal = () => {
    setOpenLogoutModal(!openLogoutModal)
  };

  document.addEventListener("scroll", addShadow);

  return (
    <>
      <HeaderSection>
        <HeaderLogo />
        {showLogo ? <Divide /> : <></>}
        <CommissionerIdentification />
      </HeaderSection>

      { isAuthenticated() && (
        <MenuSection>
          <HeaderLinkA 
            actualPage={location.pathname === "/"} 
            href={"/"}
          >
            Home
          </HeaderLinkA>
          <HeaderLink 
            actualPage={location.pathname === "/meus-ingressos"} 
            to={"/meus-ingressos"}
          >
            Meus Ingressos
          </HeaderLink>
        </MenuSection>
      )}

      <HeaderSection className="has-align-end">
        { isAuthenticated() && (
          <IdentificationWrapper>
            <SignInButton handleOpenModal={handleOpenModal} />

            <Modal>
              <ModalSair open={openLogoutModal}>
                <ShowModal onClick={handleOpenModal}></ShowModal>
                <LogoutButtonWrapper>
                  <LogoutButton
                    onClick={() => logout()}
                    onMouseLeave={handleOpenModal}
                  >
                    <LogoutButtonImg/>
                    <LogoutButtonText>
                      Sair
                    </LogoutButtonText>
                  </LogoutButton>
                </LogoutButtonWrapper>
              </ModalSair>
            </Modal>
          </IdentificationWrapper>
        )}

        <OldIosFix></OldIosFix>
        <CartButtonWrapper>
          <CartButton onClick={paymentModalHandler}>
            <CartImg/>
            {!!quantityItemInCart && (
              <CartClock />
            )}
          </CartButton>
          {showModalPayment && (
            <ModalPaymentWrapper ref={refPaymentModal}>
              <ModalPaymentDetail />
            </ModalPaymentWrapper>
          )}
        </CartButtonWrapper>
        <CartButtonMobile onClick={()=>{navigate("/carrinho")}}>
          <CartImg/>
          {!!quantityItemInCart && (
            <CartClock />
          )}
        </CartButtonMobile>

        <ToggleMenu onClick={handleOpenMenuMobile}>
            <MenuImage />
        </ToggleMenu>
      </HeaderSection>
    </>
  );
}

export default ComissionerHeader;