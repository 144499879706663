import { createContext,  useEffect,  useState } from "react";
import { EventsTicketsData } from "services/eventTicket/types";
import {
  getCommissionerEventTicketsBySlug,
  getEventTicketsBySlug,
} from "services/eventTicket/requests";

import {
  EventsDataContextData,
  EventsTicketsProviderProps,
} from "./types";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { isCommissioner } from "helpers/comissioner";
import { getSellingPoints } from "services/sellingPoints/requests";
import {
  Commissioners as CommissionersType,
  SellingPoints as SellingPointsType,
} from "services/sellingPoints/types";
import { getEvents } from "services/events/requests";
import { GetEventInLocalStorage } from "services/eventTicket/helper";

export const EventsDataContext = createContext(
  {} as EventsDataContextData
);

export function EventsDataProvider({ children }: EventsTicketsProviderProps) {
  const [showLogo, setShowLogo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [eventData, setEventData] = useState<EventsTicketsData>(
    {} as EventsTicketsData
  );
  const [sellingPoints, setSellingPoints] = useState<SellingPointsType[]>([]);
  const [commissioners, setCommissioners] = useState<CommissionersType[]>([]);
  const [sendPixel, setSendPixel] = useState<boolean>();
  
  const navigation = useNavigate();

  const location = useLocation();
  const { slug } = useParams();
  
  const notFirstRenderLogo = [
    '/eventgroup', '/events', '/carrinho', '/login', '/pagamento'
  ];

  const loadCommissionersAndSellingPoints = async (
    commissionersAndSellingPointsEventId: number
  ) => {
    const response = await getSellingPoints(
      commissionersAndSellingPointsEventId
    );
    setSellingPoints(response?.sellingPoints || []);
    setCommissioners(response?.commissioners || []);
  };

  const showEventData = async (eventSlug:string) => {
    setLoading(true);
    let response;
    try {
      response = !isCommissioner()
      ? await getEventTicketsBySlug(eventSlug)
      : await getCommissionerEventTicketsBySlug(eventSlug);

      var eventDate = new Date(response.date);
      eventDate.setDate(eventDate.getDate() + 1);

      if(eventDate <= new Date()){
        throw new Error("Evento já Realizado");
      }

      setEventData(response);
      setSendPixel(response?.sendPixel);
      loadCommissionersAndSellingPoints(response.id);
    } catch (error) {
      setEventData({} as EventsTicketsData);
      toast.error(`Evento não encontrado`);
      navigation("/");
    }
    finally{
      setLoading(false)
    }

    return response
  };

  const defineShowLogo = async () => {
    let show = true
    notFirstRenderLogo.forEach(path =>{
      if(location.pathname.includes(path)){
        show = false;
      }
    })

    if(show){
      setShowLogo(true)
      return
    }

    if(location.pathname.includes('/carrinho') || location.pathname.includes('/login') || location.pathname.includes('/pagamento')){
      const eventInCart = GetEventInLocalStorage();
      if(eventInCart == null || eventInCart.project !== 1){
        setShowLogo(true)
        return
      }
    }

    if(location.pathname.includes('/eventgroup') && slug != undefined){
      const response = await getEvents();
      const eventOjb = response.filter(item => item.Slug == slug)
      if(eventOjb?.[0]?.Project !== 1){
        setShowLogo(true)
        return
      }
    }

    if(location.pathname.includes('/events') && slug != undefined){
      const eventobj = await showEventData(slug)
      
      if(eventobj != undefined && eventobj.project !== 1){
        setShowLogo(true)
        return
      }
    }
    
  }

  useEffect(() => {defineShowLogo()},[])

  return (
    <EventsDataContext.Provider
      value={{
        showEventData,
        eventData,
        loading,
        sellingPoints,
        commissioners,
        sendPixel,
        showLogo
      }}
    >
      
      {children}
    </EventsDataContext.Provider>
  );
}

export default EventsDataProvider;