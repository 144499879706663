import { CSSProperties } from "react";

export type ButtonColor = "primary" | "secondary" | "light";
export type ButtonType = "button" | "submit";
export type ButtonSize =  "md" | "lg";

export const colorMap: Record<ButtonColor, string> = {
  primary: "var(--white)",
  secondary: "var(--white)",
  light: "var(--blue)",
};

export const backgroundcolorMap: Record<ButtonColor, string> = {
  primary: "var(--orange)",
  secondary: "var(--blue)",
  light: "var(--white)",
};

export const borderColorMap: Record<ButtonColor, string> = {
  primary: "none",
  secondary: "none",
  light: "0.1rem solid var(--blue)",
};

export const ButtonSizeMap: Record<ButtonSize, string> = {
  md: "4rem",
  lg: "4.8rem",
};

export interface StyledButtonProps {
  style?: CSSProperties;
  type?: ButtonType;
  onClick?: () => void;
  disabled?: boolean;
  customDisabled?: boolean;
  children: React.ReactNode;
  ButtonColor?: ButtonColor;
  className?: string;
  ButtonSize?: ButtonSize
}