import { useRef, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { signInFormSchema } from "../Schemas/signInFormShema";
import { useAuth } from "../../../hooks/useAuthProvider/useAuth";
import { toast } from "react-toastify";
import {
  eyesClodedImg,
} from "../../../assets/img";
import { Input } from "../../../components/Form/Input/input";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { cpf, cnpj } from "cpf-cnpj-validator";
import { cnpjMaskFromString, cpfMaskFromString } from "helpers/maks/masks";
import { SignInCredentials } from "services/user/types";
import ReCAPTCHA from "react-google-recaptcha";
import { REACT_APP_RECAPTCHA } from "constant";

import * as S from "./styles";
import { Options } from "types";
import RecoverPassword from "components/RecoveryPassword";

declare global {
  interface Window {
    recaptchaOptions: any;
  }
}

export function SignInForm() {
  const refCaptcha = useRef<ReCAPTCHA>(null);
  const [searchParams] = useSearchParams();
  const { signIn } = useAuth();
  const [loading, setLoading] = useState(false);

  // RECOVERY PASSWORD
  const [isRecoverPasswordModalOpen, setIsRecoverPasswordModalOpen] =
    useState(false);
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(signInFormSchema),
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
      captcha: "",
    },
  });

  const formIsFilled = !isDirty || !isValid;

  const handleSignIn: SubmitHandler<SignInCredentials> = async (values) => {
    let obj = { ...values };
    if (cpf.isValid(obj.email)) {
      obj.email = cpfMaskFromString(obj.email);
    } else if (cnpj.isValid(obj.email)) {
      obj.email = cnpjMaskFromString(obj.email);
    }

    setLoading(true);
    try {
      const res = await signIn(obj);

      if (res?.isPhoneVerified !== Options.FALSE) {
        const redirect = searchParams.get("redirect");
        const url = redirect ? `/${redirect}` : "/";
        window.location.replace(url);
      }
    } catch (error: any) {
      toast.error(error?.message);
      refCaptcha.current?.reset();
    } finally {
      setLoading(false);
    }
  };

  const configRecaptcha = () => {
    window.recaptchaOptions = {
      enterprise: true,
    };
  };

  const onChangeRecaptcha = (token: string | null) => {
    if (token != null) {
      setValue("captcha", token, { shouldValidate: true });
    }
  };

  const onExpired = () => {
    setValue("captcha", "", { shouldValidate: true });
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleSignIn)} className="q2l-signIn-form">
        <Input
          type="text"
          mask={cpf.isValid(getValues("email")) ? "cpf" : "email"}
          placeholder="Seu e-mail"
          {...register("email")}
          error={errors.email}
          autoComplete="off"
          inputMode="email"
        />

        <div className="q2l-signIn-form__password">
          <Input
            type="password"
            trailingIcon={eyesClodedImg}
            placeholder="Senha"
            {...register("password")}
            error={errors.password}
            autoComplete="off"
          />

          <Link
            to=""
            onClick={() => setIsRecoverPasswordModalOpen(true)}
            className="q2l-signIn-form__link"
          >
            Esqueceu a senha?
          </Link>
        </div>
        <S.CaptchaAndButtonWrapper>
          <ReCAPTCHA
            ref={refCaptcha}
            sitekey={REACT_APP_RECAPTCHA || ""}
            onChange={onChangeRecaptcha}
            asyncScriptOnLoad={configRecaptcha}
            onExpired={onExpired}
          />
          <S.EnterButton disabled={formIsFilled}>
            {loading ? (
              <FontAwesomeIcon
                icon={faSpinner}
                className="q2l-signIn-form__button_loading"
                spin={true}
              />
            ) : null}
            Entrar
          </S.EnterButton>
        </S.CaptchaAndButtonWrapper>
      </form>

      <RecoverPassword isOpen={isRecoverPasswordModalOpen} setIsOpen={setIsRecoverPasswordModalOpen} />
    </>
  );
}