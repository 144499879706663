import { ReactNode } from "react";
import { SimpleCartProps } from "services/cart/types";
import { EventsTicketsData } from "services/eventTicket/types";
import { ICreatePixForMultiplePayment, ICreditCardRequest } from "services/payments/interface";

export enum StateAddedPayments {
    SUCCESS = "success",
    ERROR = 'error',
    PROCESSING = 'processing',
    PENDING = 'pending',
    TO_START = 'to_start',
}

export enum ChosenMethod {
    CREDIT_CARD = 'credit_card',
    PIX = 'pix'
}

export type PaymentsData = {
    id: string;
    creditCardData?: ICreditCardRequest;
    pixData?: ICreatePixForMultiplePayment;
    chosenMethod: ChosenMethod;
    creditCardBrandImage?: string;
    qrCodePix?: string;
    qrCodePixBASE64?: string;
    qrCodeCreatedAt?: Date;
    totalValueToPay: number;
    state: StateAddedPayments;
    paymentId?: string;
    hasThreeDS?: boolean;
}

export type StartPendingCheckoutProps = {
    payments: PaymentsData[];
    multipleTransactionUID: string;
}

export type CheckoutContextData = {
    checkoutIsLoading: boolean;
    totalValueAdded: number;
    originalValueNeeded: number;
    currentValueNeeded: number;
    isMultiplePaymentRunning: boolean;
    isAnyPaymentError: boolean;
    existsPaymentMethodWithPix: boolean;
    addedPayments: PaymentsData[];
    setCheckoutIsLoading: (value: boolean) => void
    updateAddedPaymentById: (id: string, dataToUpdate: Partial<PaymentsData>) => void
    addMethodPayment: (payment: PaymentsData) => void;
    deleteMethodPayment: (index: string) => void;
    addOriginalValueNeeded: (value: number) => void;
    updateIsMultiplePaymentRunning: (value: boolean) => void;
    addTransactionUID: (value: string) => void
    getTransactionUID: () => string | undefined
    getTotalFromSuccessPayments: () => number
    updateIsAnyPaymentError: (value: boolean) => void;
    calculateTotalsFromAddedPayments: () => void;
    startPendingCheckout: (data: StartPendingCheckoutProps) => void;
}

export type CheckoutProviderProps = {
    children: ReactNode;
};

export interface IResponseGetPendingCheckout {
    cart: SimpleCartProps;
    event: EventsTicketsData;
    payments: PaymentsData[];
    transactionUID: string;
    sessionID: string;
}
