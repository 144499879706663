export function phoneNumberMask(e: React.FormEvent<HTMLInputElement>) {
  e.currentTarget.maxLength = 15;
  let v = e.currentTarget.value;

  v = v.replace(/\D/g, "");
  v = v.replace(/^0/, "");

  if (v.length >= 11) {
    v = v.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
  } else if (v.length > 6) {
    v = v.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
  } else if (v.length > 2) {
    v = v.replace(/^(\d\d)(\d{0,4})/, "($1) $2");
  } else if (v.trim() !== "") {
    v = v.replace(/^(\d*)/, "($1");
  }

  e.currentTarget.value = v;

  return e;
}

export function cpfMask(e: React.FormEvent<HTMLInputElement>) {
  e.currentTarget.maxLength = 14;
  let v = e.currentTarget.value;

  v = v.replace(/\D/g, "");
  v = v.replace(/(\d{3})(\d)/, "$1.$2");
  v = v.replace(/(\d{3})(\d)/, "$1.$2");

  v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");

  e.currentTarget.value = v;

  return e;
}

export const email = (event: React.FormEvent<HTMLInputElement>) => {
  let value = event.currentTarget.value;
  event.currentTarget.value = value.replaceAll(" ","");
}
export const cpfCnpjMask = (event: React.FormEvent<HTMLInputElement>) => {
  event.currentTarget.maxLength = 18;
  let v = event.currentTarget.value;

  if(v.length <= 14){
    v = v.replace(/\D/g, "");
    v = v.replace(/(\d{3})(\d)/, "$1.$2");
    v = v.replace(/(\d{3})(\d)/, "$1.$2");
    v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  } else {
    v = v.replace(/\D/g, "");
    v = v.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
  }
  event.currentTarget.value = v;
};

export const cpfCnpjMaskToString = (value: string) => {

  let v = value;

  if(v.length <= 14){
    v = v.replace(/\D/g, "");
    v = v.replace(/(\d{3})(\d)/, "$1.$2");
    v = v.replace(/(\d{3})(\d)/, "$1.$2");
    v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  } else {
    v = v.replace(/\D/g, "");
    v = v.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
  }
  return v;
};

export const cepMask = (event: React.FormEvent<HTMLInputElement>) => {
  event.currentTarget.maxLength = 8;
  let cep = event.currentTarget.value;
  cep = cep.replace(/\D/g, "");
  cep = cep.replace(/^(\d{2})(\d{3})(\d{3})/, "$1.$2-$3");
  event.currentTarget.value = cep;

  return event;
};

export function RNEMask(value: string) {
  let rne = value.replace(/([A-Za-z])(\d{6})([A-Za-z])/, "$1$2-$3");

  return rne?.toUpperCase();
}

export const RNEMaskTest = (event: React.FormEvent<HTMLInputElement>) => {
  event.currentTarget.maxLength = 14;
  let rne = event.currentTarget.value;

  rne = rne.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");

  event.currentTarget.value = rne;

  return event;
};

export const cpfMaskFromString = (value: string) => {
  let v = value;

  v = v.replace(/\D/g, "");
  v = v.replace(/(\d{3})(\d)/, "$1.$2");
  v = v.replace(/(\d{3})(\d)/, "$1.$2");

  v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");

  return v;
};

export const cnpjMask = (event: React.FormEvent<HTMLInputElement>) => {
  event.currentTarget.maxLength = 14;
  let cnpj = event.currentTarget.value;

  cnpj = cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");

  event.currentTarget.value = cnpj;

  return event;
};

export const unMask = (event: React.FormEvent<HTMLInputElement>) => {
  event.currentTarget.maxLength = 9999;

  return event;
};

export const cnpjMaskFromString = (value: string) => {
  let cnpj = value;
  cnpj = cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
  return cnpj;
};

export function dateMask(e: React.FormEvent<HTMLInputElement>) {
  e.currentTarget.maxLength = 10;
  let v = e.currentTarget.value;

  v = v.replace(/\D/g, "");
  v = v.replace(/(\d{2})(\d)/, "$1/$2");
  v = v.replace(/(\d{2})(\d)/, "$1/$2");
  v = v.replace(/(\d{4})(\d)/, "$1");

  e.currentTarget.value = v;

  return e;
}
