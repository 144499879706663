import { PaymentGatewayTransactionStatus } from "services/payments/interface";
import { StateAddedPayments } from "./types";

export const AdapterState = (state: string | number, from: string = "PaymentGatewayTransactionStatus"): StateAddedPayments => {
    if (from === "PaymentGatewayTransactionStatus") {
        switch (state) {
            case PaymentGatewayTransactionStatus.pending:
                return StateAddedPayments.PENDING
            case PaymentGatewayTransactionStatus.approved:
                return StateAddedPayments.SUCCESS
            case PaymentGatewayTransactionStatus.disapproved:
                return StateAddedPayments.ERROR
            default:
                break;
        }
    }
    return StateAddedPayments.ERROR
}
