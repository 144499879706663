import * as Yup from 'yup';
import { cpf, cnpj } from "cpf-cnpj-validator";

export const signInFormSchema = Yup.object().shape({
  email: Yup
    .string()
    .required('O e-mail ou CPF é obrigatório')
    .test("email", "E-mail ou CPF inválido", function(value){ if(!!value){ 
        return Yup.string().email().isValidSync(value) || cpf.isValid(value) || cnpj.isValid(value)
      }
      return true
    }), 
  password: Yup
    .string()
    .required('A senha é obrigatória'),
  captcha: Yup
    .string()
    .required(),
});