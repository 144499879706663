import { IBGEUrl } from ".."
import { IBGECEP, IBGECities, IBGEStates } from "./types"

export const getDataByCep = async (cep:string): Promise<IBGECEP> => {
  try{
    
    const cleanedCep = cep.replace(/\D/g, '');
    const url = `https://viacep.com.br/ws/${cleanedCep}/json`;
    const options = {
      method: "GET",
      headers: {
          'content-type': 'application/json;charset=utf-8',
      }
    }
    return await fetch(url, options).then(
      response => response.json()
    ).then(
      data => {
        return data;
      }
    )
  } catch(error: any){
    throw error.response;
  }

}

export const getStates = async (): Promise<IBGEStates[]> => {
  try {
    const response = await IBGEUrl.get('api/v1/localidades/estados?orderBy=nome')

    return response.data
  } catch (error: any) {
    throw error.response
  }
}

export const getCities = async (stateId = ''): Promise<IBGECities[]> => {
  try {
    const response = await IBGEUrl.get(`api/v1/localidades/estados/${stateId}/municipios`)

    return response.data
  } catch (error: any) {
    throw error.response
  }
}

