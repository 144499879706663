export enum Gender {
  Male = 1,
  Female,
  Others,
  PreferNotToSay,
}

export enum ChallengeStatus {
  Rejected = "cc_rejected_3ds_challenge",
  Pending = "pending_challenge"
}

export enum ThreeDSSecureStatus {
  error = "error",
  success = "success",
  pending = "pending",
}

export enum TicketTransactionPaymentStatus
{
  AGUARDANDO_PAGAMENTO = 1,
  PAGO = 3,
  PRE_AUTORIZADO = 4,
  ESTORNADO = 6,
  NAO_AUTORIZADO = 7,
  CANCELADO = 8,
}
