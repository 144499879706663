import { isCommissioner } from "helpers/comissioner";
import { CommissionerProvider } from "hooks/useCommissioner";
import { ReactNode, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CommissionersTheme } from "themes/CommissionersTheme";
import { WebsiteTheme } from "themes/WebsiteTheme";

type Q2IngressosThemeProps = {
  children: ReactNode;
  hasFooter?: boolean;
}

export function Q2IngressosTheme({ children, hasFooter }: Q2IngressosThemeProps) {

  const commissioner = isCommissioner();
  const [searchParams] = useSearchParams();
  
  useEffect(()=>{
    const validation = searchParams.get("validationEmail");

    if(validation === 'true'){
      toast.success("E-mail validado com sucesso.");
    } else if (validation === 'false'){
      toast.error("Falha ao validar e-mail.");
    }
  },[])

  return commissioner ? (
    <CommissionerProvider>
      <CommissionersTheme>
        {children}
      </CommissionersTheme>
    </CommissionerProvider>
  ) : (
    <WebsiteTheme hasFooter={hasFooter}>
      {children}
    </WebsiteTheme>
  );
}