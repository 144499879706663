import SaveGender from "components/SaveGender";
import { useAuth } from "hooks/useAuthProvider/useAuth";
import { useEffect, useState } from "react";

export const ModalSaveGenderVerification = () => {
  const [visible, setVisible] = useState(true);
  const { user } = useAuth();
  const [isNeedVerification, setIsNeedVerification] = useState(false);

  useEffect(() => {

    const isEnabledPage = window.location.pathname !== "/dados-da-conta"

    setIsNeedVerification(user?.gender === null || user?.gender === 0 && isEnabledPage);
  }, [user, window.location.pathname]);

  return isNeedVerification ? (
    <SaveGender
      setVisible={setVisible}
      visible={visible}
    />
  ) : null;
};
