import { Link, useNavigate } from "react-router-dom";

import { SignInButton } from "../SignInButton";
import { useAuth } from "../../hooks/useAuthProvider/useAuth";

import {
  arrowMobileImg,
  cartMobileImg,
  closeMobileImg,
  logoutMobileImg,
  ticketsMobileImg,
} from "../../assets/img";

import { useSwipeable } from "react-swipeable";
import { useCart } from "hooks/useCart/useCart";
import { REACT_APP_CDN_IMG } from "constant";

export function MenuMobile() {
  const navigate = useNavigate();
  const { logout, isAuthenticated } = useAuth();
  const { clearCart } = useCart();
  //const hasMarginButton = !isAuthenticated() ? "has-margin-button" : "";

  const handleCloseMenuMobile = () => {
    document.documentElement.classList.remove("is-menu-opened");
  };

  const goToMinhaConta = () => {
    navigate("/minha-conta");
    handleCloseMenuMobile();
  };

  const handlers = useSwipeable({
    //onSwipedLeft: () => slide(NEXT),
    onSwipedRight: () => handleCloseMenuMobile(),
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  const functionLogout = () => {
    clearCart();
    logout();
  };

  return (
    <div className="q2c-menu-mobile" {...handlers}>
      <aside className="q2c-menu-mobile__aside">
        <main className="q2c-menu-mobile__content">
          <header className="q2c-menu-mobile__header">
            <Link to="/" onClick={handleCloseMenuMobile} aria-label="home">
              <img
                src={`${REACT_APP_CDN_IMG}/filters:format(webp):quality(100)/img/website-images/q2logo.png`}
                alt="Logo Q2 Ingressos"
                className="q2c-menu-mobile__logo"
                width={62}
                height={43}
              />
            </Link>

            <button type="button" onClick={handleCloseMenuMobile}>
              <img 
                src={closeMobileImg} 
                alt="Icone fechar menu"
                width={32}
                height={32}
              />
            </button>
          </header>

          <div className="q2c-menu-mobile__user">
            <SignInButton
              handleOpenModal={goToMinhaConta}
              handleCloseMenuMobile={handleCloseMenuMobile}
            />
          </div>

          <nav className="q2c-menu-mobile__nav">
            <ul className="q2c-menu-mobile__list">
              <li className="q2c-menu-mobile__item">
                <Link
                  to="/meus-ingressos"
                  className="q2c-menu-mobile__link"
                  onClick={handleCloseMenuMobile}
                >
                  <div className="q2c-menu-mobile__item-wrapper">
                    <img
                      src={ticketsMobileImg}
                      alt="Icone de Ingresso"
                      className="q2c-menu-mobile__icon"
                      width={32}
                      height={32}
                    />
                    Meus Ingressos
                  </div>

                  <img
                    src={arrowMobileImg}
                    alt="Seta"
                    className="q2c-menu-mobile__arrow-icon"
                    width={24}
                    height={24}
                  />
                </Link>
              </li>

              <li className="q2c-menu-mobile__item">
                <Link
                  to="/carrinho"
                  className="q2c-menu-mobile__link"
                  onClick={handleCloseMenuMobile}
                >
                  <div className="q2c-menu-mobile__item-wrapper">
                    <img
                      src={cartMobileImg}
                      alt="Icone de carrinho"
                      className="q2c-menu-mobile__icon"
                      width={32}
                      height={32}
                    />
                    Meu carrinho
                  </div>

                  <img
                    src={arrowMobileImg}
                    alt="Seta"
                    className="q2c-menu-mobile__arrow-icon"
                    width={24}
                    height={24}
                  />
                </Link>
              </li>

              {/*
              <li className="q2c-menu-mobile__item">
                <Link
                  to="/proximos-eventos"
                  className="q2c-menu-mobile__link"
                  onClick={handleCloseMenuMobile}
                >
                  <div className="q2c-menu-mobile__item-wrapper">
                    <img
                      src={calendarMobileImg}
                      alt="Icone de eventos"
                      className="q2c-menu-mobile__icon"
                    />
                    Próximos eventos
                  </div>

                  <img
                    src={arrowMobileImg}
                    alt="Seta"
                    className="q2c-menu-mobile__arrow-icon"
                  />
                </Link>
              </li>
                <li className={`q2c-menu-mobile__item ${hasMarginButton}`}>
                  <Link
                    to="/mais-populares"
                    className="q2c-menu-mobile__link"
                    onClick={handleCloseMenuMobile}
                  >
                    <div className="q2c-menu-mobile__item-wrapper">
                      <img
                        src={starMobileImg}
                        alt="Icone de estrela"
                        className="q2c-menu-mobile__icon"
                      />
                      Mais acessados
                    </div>

                    <img
                      src={arrowMobileImg}
                      alt="Seta"
                      className="q2c-menu-mobile__arrow-icon"
                    />
                  </Link>
                </li>
              */}
            </ul>

            {isAuthenticated() && (
              <button className="q2c-menu-mobile__logout" type="button">
                <img
                  src={logoutMobileImg}
                  alt="Icone de logout"
                  className="q2c-menu-mobile__logout-img"
                />
                <span
                  onClick={() => functionLogout()}
                  className="q2c-menu-mobile__logout-text"
                >
                  Sair da conta
                </span>
              </button>
            )}
          </nav>
        </main>

        <footer className="q2c-menu-mobile__footer">
          <p className="q2c-menu-mobile__footer-copy">
            Q2 Ingressos LTDA - CNPJ sob o nº 21.233.248/0001-72 Av.
            Vereador Jose Granzotte, nº 2345, Jardim Piratininga, Franca-SP, CEP
            14403-597
          </p>
        </footer>
      </aside>
    </div>
  );
}
