import ReclameAquiBadge from "components/ReclameAquiBadge";

import * as S from "./styles";
import {
  BrandsIcon,
  NewQ2BankIcon,
  YoutubeIcon,
  InstagramIcon,
  FacebookIcon,
  AppStoreIcon,
  GooglePlayIcon,
  AbrapeImage,
  SSL,
  ComplianceImage,
  LgpdImage,
  VotingReclameAqui
} from "assets/img";
import { MenuData, MenuList, menuData } from "./data";

export const NewFooter = () => {
  const renderMenuLink = (links: MenuList[]) =>
    links.map((link, index) => (
      <S.MenuLink key={index} href={link.url} target={link.target}>
        {link.name}
      </S.MenuLink>
    ));

  const renderMenuItem = (item: MenuData, key: number) => (
    <S.MenuItemWrapper key={key}>
      <S.MenuTitle>{item.title}</S.MenuTitle>
      {renderMenuLink(item.menuList)}
    </S.MenuItemWrapper>
  );

  return (
    <S.Container>
      <S.ContainerFooter>
        <S.HeaderFooter>
          <S.BrandsContainer>
            <S.BrandsFooter src={BrandsIcon} />
            <S.BrandsFooter src={NewQ2BankIcon} />
          </S.BrandsContainer>
          <S.SocialMediaWrapper>
            <S.SocialMediaButton
              href="https://www.facebook.com/q2ingressos"
              target="_blank"
              aria-label="facebook"
            >
              <S.SocialMediaIcon src={FacebookIcon} />
            </S.SocialMediaButton>
            <S.SocialMediaButton
              href="https://www.instagram.com/q2ingressos"
              target="_blank"
              aria-label="instagram"
            >
              <S.SocialMediaIcon src={InstagramIcon} />
            </S.SocialMediaButton>
            <S.SocialMediaButton
              href="https://www.youtube.com/channel/UCP6TEYEeCAZ4vT9II0_F-vg"
              target="_blank"
              aria-label="youtube"
            >
              <S.SocialMediaIcon src={YoutubeIcon} />
            </S.SocialMediaButton>
          </S.SocialMediaWrapper>
        </S.HeaderFooter>

        <S.BadgeInfoWrapper>
          <S.StoreDowloadsWrapper>
            <S.AppsTitle>Faça o download do nosso aplicativo!</S.AppsTitle>
            <S.AppsWrapper>
              <S.AppButton href="https://play.google.com/store/apps/details?id=br.com.quero2ingressos&hl=pt_BR&gl=US">
                <S.AppImg src={GooglePlayIcon} />
              </S.AppButton>
              <S.AppButton href="https://apps.apple.com/br/app/q2-ingressos/id1441533691">
                <S.AppImg src={AppStoreIcon} />
              </S.AppButton>
            </S.AppsWrapper>
          </S.StoreDowloadsWrapper>
          <S.BadgeWrapper>
            <S.BadgeWithTextWrapper>
              <S.ImageWrapper>
                <S.ImageText>Associado</S.ImageText>
                <S.FixImage src={AbrapeImage} />
              </S.ImageWrapper>
              <S.ImageWrapper>
                <S.ImageText>Site seguro</S.ImageText>
                <S.FixImage src={SSL} />
              </S.ImageWrapper>
            </S.BadgeWithTextWrapper>
            <S.BadgeWithoutTextWrapper>
              <S.StampsImage src={ComplianceImage} />
              <S.StampsImage src={LgpdImage} />
              <ReclameAquiBadge />
              <S.AppButton href="https://www.reclameaqui.com.br/empresa/quero-2-ingressos/premio/" target="_blank">
                <S.StampsImage src={VotingReclameAqui} />
              </S.AppButton>
            </S.BadgeWithoutTextWrapper>
          </S.BadgeWrapper>
        </S.BadgeInfoWrapper>

        <S.MenuWrapper>
          <S.MenuItemWrapper>
            <S.MenuTitle>Atendimento que faz a diferença!</S.MenuTitle>
            <S.ServiceText>
              Fale conosco através do WhatsApp, pelo número 0800 232 0800.
            </S.ServiceText>
            <S.ServiceText>Segunda a sábado: das 8h às 23h.</S.ServiceText>
          </S.MenuItemWrapper>
          {menuData.map((menu, index) => renderMenuItem(menu, index))}
        </S.MenuWrapper>

        <S.CopyText>
          Q2 Ingressos LTDA - CNPJ sob o nº 21.233.248/0001-72 Av. Vereador Jose
          Granzotte, nº 2345, Jardim Piratininga, Franca-SP, CEP 14403-597
        </S.CopyText>
      </S.ContainerFooter>
    </S.Container>
  );
};
