import { createContext, useEffect, useState } from "react";
import { toast } from "react-toastify";

import { getCities, getStates } from "../../services/Address/requests";
import { ICity, IState } from "../../services/Address/types";
import { AddressContextType, AddressProviderProps } from "./types";

export const AddressContext = createContext<AddressContextType>(
  {} as AddressContextType
);

export function AddressProvider({ children }: AddressProviderProps) {
  const [states, setStates] = useState<IState[]>([]);
  const [cities, setCities] = useState<ICity[]>([]);

  const showStates = async () => {
    try {
      const response = await getStates();
      setStates(response);
    } catch (error) {
      toast.error("Não foi possível carregar os estados, recarregue a página e tente novamente.")
    }
  };

  const getStateByIBGEId = (IBGEId: number) => {
    const state = states.find( item => item.IdIBGE === IBGEId)
    return state;
  }

  const showCitiesByStateIBGEId = async (stateId: number) => {
    const state = getStateByIBGEId(stateId)
    if(state){
      try {
        const response = await getCities(state.StateID);
        setCities(response);
      } catch (error) {
        toast.error("Não foi possível carregar os estados, recarregue a página e tente novamente.")
      }
    }
  };

  const showCitiesByStateId = async (stateId: number) => {
    if(stateId){
      try {
        const response = await getCities(stateId);
        setCities(response);
      } catch (error) {
        toast.error("Não foi possível carregar as cidades, recarregue a página e tente novamente.")
      }
    }
  };

  useEffect(() => {
    showStates();
  }, []);

  return (
    <AddressContext.Provider value={{ states, cities, showCitiesByStateId, showCitiesByStateIBGEId }}>
      {children}
    </AddressContext.Provider>
  );
}

export default AddressProvider;