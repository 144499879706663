import WhatsAppScript from 'components/WhatsAppScript'

const WhatsAppScriptForMobile = () => {
  return (
    <div>
      <WhatsAppScript />
    </div>
  )
}

export default WhatsAppScriptForMobile