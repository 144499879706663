import { UserToken } from "./types";

export function setUserLocalStorage(token: UserToken | null) {
  if(typeof window !== "undefined"){
    window.localStorage.setItem("u", JSON.stringify(token));
  }
}

export function getUserLocalStorage() {
  const json = typeof window !== "undefined" ? window.localStorage.getItem("u") : null;

  if (!json) return null;
  try{
    const user = JSON.parse(json);
    return user ?? null;
  } catch(e:any){
    if(typeof window !== "undefined"){
      window.localStorage.removeItem("u");
    }
    return null;
  }
}

export const clearUserLocalStorage = () => {
  if(typeof window !== "undefined"){
    window.localStorage.removeItem("u");
  }
};
