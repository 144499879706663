import { baseURL } from '..';
import { IState, ICity} from './types'

export const getStates = async(): Promise<IState[]> => {
    try {
        const { data: response } = await baseURL.get('api/addresses/states');
        return response;
      } catch (error: any) {
        throw error.response;
      }
}

export const getCities = async(stateId : number): Promise<ICity[]> => {
    try {
        const { data: response } = await baseURL.get(`api/addresses/cities/${stateId}`);
        return response;
      } catch (error: any) {
        throw error.response;
      }
}