import { useContext } from "react"
import { EventsDataContext } from "."
import { EventsDataContextData } from "./types";

export function useEventsData() : EventsDataContextData {
  const context = useContext(EventsDataContext)

  return context
}

export default useEventsData;