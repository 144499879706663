import { forwardRef, ForwardRefRenderFunction } from "react";

import { InputProps } from "../types";

import { eyesOpenImg, warningImg } from "../../../assets/img";
import { handleKeyDownMask, handleKeyUpMask } from "helpers/maks";
import Tooltip from "components/Tooltip";
import { ErrorIcon, ErrorMessage, ErrorWrapper, InputStyle, LeadingIcon, TrailingWrapper, WrapperIcons } from "./styles";

const InputBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  {
    leadingIcon,
    trailingIcon,
    mask,
    error = null,
    isDisabled,
    tooltipMessage,
    idTooltip,
    autoComplete,
    ...props
  },
  ref
) => {
  if (props.type === "password") {
    props.type = props.type;
    trailingIcon = eyesOpenImg;
  }

  const hasError = error ? "is-error" : "";
  const isDisable = isDisabled ? "is-disable" : "";

  return (
    <div className="q2c-text-field-container">
      <div className={`q2c-text-field ${hasError} ${isDisable}`}>
        <WrapperIcons leading={Boolean(leadingIcon)}>
          <LeadingIcon src={leadingIcon}/>
          <TrailingWrapper show={tooltipMessage !== undefined}>
            <Tooltip
              icon={trailingIcon}
              place="top"
              id={idTooltip || ""}
              message={tooltipMessage || ""}
            />
          </TrailingWrapper>
        
        </WrapperIcons>

        <InputStyle
          {...props}
          error={error !== null}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handleKeyDownMask(e, mask)}
          onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => handleKeyUpMask(e, mask)}
          disabled={isDisabled}
          autoComplete={autoComplete ? autoComplete : "on"}
          ref={ref}
          leading={leadingIcon !== undefined}
          onWheel={(e: React.WheelEvent<HTMLInputElement>) => e.currentTarget.blur()}
        />
      </div>

      <ErrorWrapper show={error !== null}>
        <ErrorIcon src={warningImg} alt="" />
        <ErrorMessage>
          {error?.message}
        </ErrorMessage>
      </ErrorWrapper>

    </div>
  );
};

export const Input = forwardRef(InputBase);
