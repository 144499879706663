import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

  :root {
    --blue:#005B8E;
    --landingblue: #2A38F6;
    --orange: #FF751B;
    --red: #FF0000;

    --white: #fff;
    --black: #000;

    --gray50: #B4D5F4;
    --gray100: #E1E1E1;
    --gray200: #DBDBDB;
    --gray300: #A3A3A3;
    --gray400: #9F9F9F;
    --gray500: #666666;
    --gray555: #555555;
    --gray600: #505050;
    --gray700: #292929;

  }
  
  * {
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    outline: none;
    border: none;
    box-sizing: border-box;
  }

  img {
    max-width: 100%;
  }

  html {
    font-size: 62.5%;
    scroll-behavior: smooth;
    //@media (max-width: 1024px) {
      //font-size: 58%;
    //}
  }

  body, input, textarea, button {
    font-family: "Work Sans", sans-serif;
  }

  html,
  body {
    @media (max-width: 800px) {
      overflow-x: hidden;
    }
  }

  button {
    background-color: transparent;
    cursor: pointer;
  }

  .Toastify__toast-body {
    font-size: 1.4rem;
  }

  .container {
   width: 100%;
   max-width: 166.4rem;
   padding: 0 10.2rem;
   margin: 0 auto;

   @media (max-width:576px) {
      padding: 0 2rem;
   }
  }

  #lasteventsSwiper{
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 55px;
    @media (min-width:768px) {
      width: calc(100vw - 280px);
    }
    @media (max-width:768px) {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

`;

export { GlobalStyle };
