import { useCommissioner } from "hooks/useCommissioner/useCommissioner";
import {
  Name,
  TextWrapper,
  Title,
  Wrapper,
} from "./styles";

const CommissionerIdentification = () => {
  const { commissioner } = useCommissioner();
  const hasComissioner = !!commissioner;

  return (
    <Wrapper show={hasComissioner}>
      <TextWrapper>
        <Name>{commissioner?.name}</Name>
        <Title>Comissário autorizado</Title>
      </TextWrapper>
    </Wrapper>
  );
};

export default CommissionerIdentification;
