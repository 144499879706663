import { REACT_APP_CDN_IMG } from "constant";
import { Link } from "react-router-dom";

import { SignIn } from "../../../layouts/SignIn";

export function SignInRegisterMobile() {
  return (
    <>
      <div className="q2l-signIn-register-mobile">
        <div className="container">
          <header className="q2l-signIn-register-mobile__header">
            <div className="q2l-signIn-register-mobile__logo">
              <a
                href="/"
                className="q2l-signIn-register-mobile__link"
                aria-label="home"
              >
                <img
                  src={`${REACT_APP_CDN_IMG}/filters:format(webp):quality(100)/img/website-images/q2logo.png`}
                  alt="Logo Q2 Ingressos"
                  className="q2l-signIn-register-mobile__img"
                />
              </a>
            </div>
          </header>
          <main className="q2l-signIn-register-mobile__content">
            <div className="q2l-signIn-register-mobile__signIn">
              <SignIn />
              <p className="q2l-signIn-register-mobile__text">
                Ainda não é cadastrado?
                <Link
                  to="/cadastrar-mobile"
                  className="q2l-signIn-register-mobile__link"
                >
                  {" "}
                  Cadastre-se
                </Link>
              </p>
            </div>
          </main>
          <footer className="q2l-signIn-register-mobile__footer"></footer>
        </div>
      </div>
    </>
  );
}
