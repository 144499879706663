import styled from "styled-components";
import { ButtonProps } from "./types";

export const CaptchaAndButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 800px) {
    flex-direction: column;
    gap: 16px;
  }
`;

export const EnterButton = styled.button.attrs({ type: "submit" })<ButtonProps>`
  width: 100%;

  display: block;
  height: 4.8rem;
  border-radius: 0.8rem;
  font-weight: 600;
  font-size: 1.6rem;
  transition: all 0.3s;
  color: var(--white);

  margin-top: 1rem;

  cursor: ${(props) => (props.disabled ? " not-allowed" : "pointer")};
  background: ${(props) =>
    props.disabled ? "var(--gray200)" : "var(--orange)"};

  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ModalTitle = styled.h2`
  font-size: 2.4rem;
  font-weight: 600;
  color: #005b8e;
`;

export const ModalCloseButton = styled.img``;

export const ModalDescription = styled.p`
  font-size: 1.6rem;
  font-weight: 400;
  color: #292929;

  margin-top: 12px;
`;

export const ModalWrapperInput = styled.div`
  margin-top: 26px;
`;

export const ModalWrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;

  width: 20%;

  margin-left: auto;
  margin-top: 48px;

  @media (max-width: 480px) {
    width: 30%;
  }
`;
