import styled from "styled-components";
import { WrapperType } from "./types";

export const Wrapper = styled.div<WrapperType>`
    display: ${(props) => { return props.show ? "flex" : "none" }};
    justify-content: center;
    align-items: center;
`;
export const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: .8rem;
    height: 3rem;
    min-width: 3rem;
    max-width: 3rem;
    border-radius: 3rem;
    border: 2px solid var(--orange);
`;
export const Image = styled.img`
    height: 2.8rem;
    min-width: 2.8rem;
    max-width: 2.8rem;
    border-radius: 3rem;
`;
export const TextWrapper = styled.div`

`;
export const Name = styled.div`
    color: #ffffff;
    size: 1.6rem;
    line-height: 1.6rem;
    font-weight: 600;
`;
export const Title = styled.div`
    color: #ffffff;
    min-width: 115px;
    line-height: 1.35rem;
    size: 1.2rem;
    font-weight: 400;
`;