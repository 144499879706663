
export async function setSessionIdLocalStorage(sessionId: string) {
  if(typeof window !== "undefined"){
    window.localStorage.setItem("sessionId", JSON.stringify(sessionId || ""));
  }
}

export const getSessionId = async () => {
  const json = typeof window !== "undefined" ? await window.localStorage.getItem("sessionId") : null;

  if (json && JSON.parse(json) !== "") {
    return JSON.parse(json);
  }

  return undefined;
}