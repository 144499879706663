type ItemReducerProps<T, K> = {
  items: T[];
  keyValue: K;
};

type ItemReducerWithMultiplierProps<T, M, K> = {
  items: T[];
  keyMultiplier: M;
  keyValue: K;
};

export const ItemReducer = <T, K extends keyof T>({
  items,
  keyValue,
}: ItemReducerProps<T, K>) => {
  // eslint-disable-next-line @typescript-eslint/default-param-last
  return items?.reduce((acc = 0, item) => {
    const value = item?.[keyValue] as any;
    return acc + value;
  }, 0);
};

export const ItemReducerWithMultiplier = <
  T,
  M extends keyof T,
  K extends keyof T
>({
  items,
  keyMultiplier,
  keyValue,
}: ItemReducerWithMultiplierProps<T, M, K>) => {
  // eslint-disable-next-line @typescript-eslint/default-param-last
  return items?.reduce((acc = 0, item) => {
    const multiple = item?.[keyMultiplier] as any;
    const value = item?.[keyValue] as any;
    return acc + multiple * value;
  }, 0);
};
