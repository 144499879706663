import Modal from 'components/NewModal'
import { useAuth } from 'hooks/useAuthProvider/useAuth'
import { useCart } from 'hooks/useCart/useCart'
import { setMultipleTransactionUID } from 'hooks/useCheckout/handleLocalStorage'
import { useEffect, useState } from 'react'
import { generateCart } from 'services/cart/requests'
import { SetEventInLocalStorage } from 'services/eventTicket/helper'
import { pendingMultipleTransaction } from 'services/payments/requests'
import { setSessionIdLocalStorage } from 'services/sessionId/requests'
import * as S from './styles'

export function ModalPendingCheckout() {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const { setCartToPedingCheckout } = useCart()
  const { isAuthenticated } = useAuth()

  const existsPendingCheckout = async () => {
    if (!isAuthenticated()) return
    const response = await pendingMultipleTransaction()
    if (!response || !response.payments) return
    if (response.payments.length <= 0) return
    setMultipleTransactionUID(response.transactionUID)
    SetEventInLocalStorage(response.event)
    setCartToPedingCheckout(generateCart(response.cart))
    setSessionIdLocalStorage(response.sessionID)
    setIsOpenModal(true)
  }

  useEffect(() => {
    existsPendingCheckout()
  }, [])

  return (
    <Modal
      isOpen={isOpenModal}
      onClose={() => {
        setIsOpenModal(false)
      }}
      closeOnClickOutsideModal={false}
      closeOnKeyEscape={false}
      padding={{
        default: '32px',
      }}
      width="635px"
    >
      <S.Title>Existe uma compra em andamento</S.Title>
      <S.Description>
        Olá! Notamos que você tem uma compra de ingressos em andamento. Para continuar de onde parou, basta clicar no botão
        abaixo.
      </S.Description>
      <S.Footer>
        <S.Button to={'/pagamento'}>Voltar para minha compra</S.Button>
      </S.Footer>
    </Modal>
  )
}
