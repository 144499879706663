import {  HomeLink, Logo } from "./styles";
import { isCommissioner } from "helpers/comissioner";
import { useEventsData } from "hooks/useEventsData/useEventsData";

const HeaderLogo = () => {
  const addSpacing = isCommissioner();
  const { showLogo } = useEventsData();

  return showLogo ?
    (
      <>
        <HomeLink href="/" aria-label="home" addspacing={addSpacing}>
          <Logo 
            width={62}
            height={43}
          /> 
        </HomeLink>
      </>
    ) : <></>
}

export default HeaderLogo