import styled, { keyframes } from "styled-components";

import { LoadingSpinnerProps } from "./types";

export const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// Styled component para o spinner
export const Spinner = styled.div<LoadingSpinnerProps>`
  border: ${(props) => props.size / 10}px solid rgba(219, 219, 219, 0.5);
  border-top: ${(props) => props.size / 10}px solid ${(props) => props.color};
  border-radius: 50%;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  animation: ${spin} 1s linear infinite;
`;