export const getSubdomain = () => {
  return window.location.host.split(".")[0] !== "www"
    ? window.location.host.split(".")[0]
    : window.location.host.split(".")?.[1];
};

export const isCommissioner = (): boolean => {
  return getSubdomain() === process.env.REACT_APP_COMISSARIOS_DOMAIN;
};

export const getLocalCommissioner = (): { id: string } | undefined => {

  const localInfo = typeof window !== "undefined" ? 
    window.localStorage.getItem("commissioner") : null;
     
  if (!localInfo) {
    window.location.href = "https://www.quero2ingressos.com.br";
    return;
  }
  
  const commissioner = JSON.parse(localInfo);
  return commissioner;
};
