import { Link } from "react-router-dom";
import styled from "styled-components";

export const Title = styled.div`
    font-size: 18px;
    font-weight: 600;
    line-height: 23.4px;
    margin-bottom: 16px;
`

export const Description = styled.div`
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 32px;

    @media (max-width: 768px) {
        line-height: 21px;
    }
`

export const Footer = styled.div`
    display: flex;
    justify-content: center;
`

export const Button = styled(Link)`
    background-color: #005B8E;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    width: 253px;
    height: 40px;
    padding: 8px 24px 8px 24px;
    border-radius: 8px;
    opacity: 0px;
    outline: none;
`;
