import { getIp, getUserAgent } from "services/Geolocation";
import {
  AddToCartProps,
  InitiateCheckoutProps,
  PurchaseProps,
  ViewEventProps,
} from "./types";
import { baseURL } from "..";
import { Token } from "hooks/useAuthProvider/types";
import jwt from "jwt-decode";
import { SHA256 } from "crypto-js";

export function getUserLocalStorage() {
  const json = typeof window !== "undefined" ? window.localStorage.getItem("u") : null;
  if (!json) return null;

  try{
    const user = JSON.parse(json);
    return user ?? null;
  } catch(e:any){
    if(typeof window !== "undefined"){
      window.localStorage.removeItem("u");
    }
    return null;
  }
}


const formatWithTwoDecimals = (num: number): string => {
  if (Number.isInteger(num)) {
    return num.toFixed(2); 
  }
  return num.toString(); 
}

const getUserData = () => {
  const json = typeof window !== "undefined" ? window.localStorage.getItem("u") : null;
  if (!json) return {};
  try{

    const user = JSON.parse(json);
    const obj: Token = jwt(user.token);
    const newUser = obj?.user;
    if (!newUser) return {};

    return {
      em: [SHA256(newUser.email.toLowerCase()).toString()],
      ph: [SHA256(
        newUser.phone
          .replaceAll(" ", "")
          .replaceAll("(", "")
          .replaceAll(")", "")
          .replaceAll("-", "")
      ).toString()],
      fn: [SHA256(newUser.name.toLocaleLowerCase().split(" ")[0]).toString()],
      db: [SHA256(newUser.birthDate.split("T")[0].replaceAll("-", "")).toString()],
      ct: [SHA256(
        newUser.cityName.toLocaleLowerCase().replaceAll(" ", "")
      ).toString()],
      st: [SHA256(
        newUser.stateName.toLocaleLowerCase().replaceAll(" ", "")
      ).toString()],
    };
  } catch(e:any){
    if(typeof window !== "undefined"){
      window.localStorage.removeItem("u");
    }
    return {};
  }
};

export const setSendPixelInLocalStorage = (send:string) => {
  if(typeof window !== "undefined"){
    window.localStorage.setItem("sendPixel", send);
  }
}

export const getSendPixelInLocalStorage = () : boolean => {
  return typeof window !== "undefined" ? window.localStorage.getItem("sendPixel") == "sim" : false;
}

export const clearSendPixel = () => {
  if(typeof window !== "undefined"){
    window.localStorage.removeItem("sendPixel");
  }
}

export const addToCart = async ({ eventId, value, itemQuantity, }: AddToCartProps) => {
  const userData = getUserData();
  const data = {
    data: [
      {
        event_name: "AddToCart",
        event_time: Math.round(new Date().getTime() / 1000),
        action_source: "website",
        user_data: {
          client_ip_address: await getIp(),
          client_user_agent: getUserAgent,
          ...userData,
        },
        custom_data: {
          currency: "BRL",
          value: formatWithTwoDecimals(value),
          num_items: itemQuantity,
        },
      },
    ],
  };
  baseURL.post("api/website/SendPixel", {
    EventId: eventId,
    payload: JSON.stringify(data),
  });
};

export const viewEvent = async ({ eventId }: ViewEventProps) => {
  const userData = getUserData();
  try {
    const data = {
      data: [
        {
          event_name: "ViewContent",
          event_time: Math.round(new Date().getTime() / 1000),
          action_source: "website",
          user_data: {
            client_ip_address: await getIp(),
            client_user_agent: getUserAgent,
            ...userData,
          },
        },
      ],
    };

    baseURL.post("api/website/SendPixel", {
      EventId: eventId,
      payload: JSON.stringify(data),
    });
  } catch (e) {}
};

export const purchase = async ({ eventId, value, itemQuantity, }: PurchaseProps) => {
  const userData = getUserData();
  const data = {
    data: [
      {
        event_name: "Purchase",
        event_time: Math.round(new Date().getTime() / 1000),
        action_source: "website",
        user_data: {
          client_ip_address: await getIp(),
          client_user_agent: getUserAgent,
          ...userData,
        },
        custom_data: {
          currency: "BRL",
          value: formatWithTwoDecimals(value),
          num_items: itemQuantity,
        },
      },
    ],
  };

  baseURL.post("api/website/SendPixel", {
    EventId: eventId,
    payload: JSON.stringify(data),
  });
};

export const initiateCheckout = async ({
  eventId,
  value,
  itemQuantity,
}: InitiateCheckoutProps) => {
  const userData = getUserData();

  console.log(formatWithTwoDecimals(value));
  const data = {
    data: [
      {
        event_name: "InitiateCheckout",
        event_time: Math.round(new Date().getTime() / 1000),
        action_source: "website",
        user_data: {
          client_ip_address: await getIp(),
          client_user_agent: getUserAgent,
          ...userData,
        },
        custom_data: {
          currency: "BRL",
          value: formatWithTwoDecimals(value),
          num_items: itemQuantity,
        },
      },
    ],
  };

  baseURL.post("api/website/SendPixel", {
    EventId: eventId,
    payload: JSON.stringify(data),
  });
};
