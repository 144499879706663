import { Modal } from "components/Modal";
import { Props } from "./types";
import { genderDataFormSchema } from "./types";
import {
  Container,
  Title,
  ContainerButton,
  TutorialText,
  InputWrapper,
} from "./style";
import { useState } from "react";
import { Button } from "components/Button";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { genderOptions } from "utils/user";
import {
  Controller,
  useForm
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormSelect from "components/Form/FormSelect";
import { useAuth } from "hooks/useAuthProvider/useAuth";


const SaveGender = ({ visible, setVisible }: Props) => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [selectedGender, setSelectedGender] = useState(0);
  const { user, updateMyAccount } = useAuth();

  setVisible(true)

  const {
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(genderDataFormSchema),
    mode: "onChange",
  });

  const handlerGender = (event: any) => {
    setSelectedGender(event?.value)
    setValue("gender", event?.value, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const canSave = genderOptions.find(option => option.value === selectedGender) !== undefined;

  const trySaveGender = async () => {
    try {

      if (canSave)
        setLoading(true);
      let data = {
        cityIBGEId: user?.cityIBGE,
        stateIBGEId: user?.stateIBGE,
        gender: selectedGender,
        phone: user?.phone,
        login: user?.email,
        Name: user?.name,
        cpf: user?.cpf,
        birthDate: user?.birthDate,
        Id: user?.id
      }

      await updateMyAccount(data);

      toast.success("Gênero salvo com sucesso!");

      const redirect = searchParams.get("redirect");
      const url = redirect ? `/${redirect}` : "/";
      window.location.replace(url);
    } catch (error) {
      toast.error(
        "Erro ao salvar gênero."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal>
      <form
        style={{ padding: "2rem", zIndex: 999 }}
        className={`q2c-modal ${visible ? "is-active" : ""}`}
      >
        <div className="q2c-modal__overlay"></div>

        <Container>
          <Title>Complete seu cadastro</Title>

          <TutorialText>
            Verificamos que seu cadastro está incompleto, por favor,
            preencha o campo de gênero e deixe seu perfil completo.
          </TutorialText>

          <InputWrapper>
            <Controller
              control={control}
              name="gender"
              render={({ field: { value } }) => (
                <FormSelect
                  value={genderOptions.filter((item) => item.value == value)}
                  placeholder="Selecione seu gênero"
                  onChange={handlerGender}
                  options={genderOptions}
                  error={errors?.gender}
                />
              )}
            />
          </InputWrapper>

          <ContainerButton>
            <Button
              type="submit"
              disabled={loading || !canSave}
              onClick={trySaveGender}
            >
              {loading && (
                <FontAwesomeIcon
                  icon={faSpinner}
                  className="q2l-signIn-form__button_loading"
                  spin={true}
                />
              )}

              {!loading ? "Salvar" : ""}

            </Button>
          </ContainerButton>


        </Container>
      </form>
    </Modal>
  );
};

export default SaveGender;
