import { baseURL } from "services";
import { ConcludePasswordChangeProps } from "./types";
import { DEFAULT_ERROR_MESSAGE } from "constant";

export const recoveryPassword = async (identifier: string): Promise<string> => {
  try {
    const { data: response } = await baseURL.post(
      "api/v2/webiste/sendResetPasswordEmail",
      {
        identifier,
      }
    );
    return response;
  } catch (error: any) {
    throw new Error(error.response.data.Message || DEFAULT_ERROR_MESSAGE);
  }
};

export const postChangePassword = async (email: string) => {
  try {
    const token = "56f869f6c051130a44cffbbc1c304525";
    const response = await baseURL.post("api/website/resetpassword", {
      token,
      email,
    });
    return response.data;
  } catch (error: any) {
    throw error.response;
  }
};

export const concludePasswordChange = async ({
  id,
  code,
  password,
}: ConcludePasswordChangeProps) => {
  try {
    const response = await baseURL.post("api/website/NewResetPassword", {
      id,
      code,
      password,
    });
    if (response === undefined) {
      throw new Error(
        "Erro ao comunicar com o servidor, verifique sua conexão e tente novamente"
      );
    }

    return response.data;
  } catch (error: any) {
    throw error.message === "Network Error"
      ? "Falha de conexão, verifique sua internet e tente novamente."
      : error.response?.data?.Message;
  }
};
