import { createContext, useEffect, useState } from "react";
import { ViewportContextData, ViewportProviderProps } from "./types";

export const viewportContext = createContext({} as ViewportContextData);

export const ViewportProvider = ({ children }: ViewportProviderProps) => {

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <viewportContext.Provider value={{ width, height }}>
      {children}
    </viewportContext.Provider>
  );
};


