import styled from "styled-components";

export const Container = styled.div`
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);
  z-index: 999;
  padding: 24px;

  max-width:  407px;
  width:100%;

  max-height: 418px;
  height: 100%;
`;

export const Title = styled.strong`
  color: #000;
  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; 

  margin-bottom: 0.5rem;

  display: block;
`

export const Description = styled.p`
  color: #484648;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  margin-top: 0.5rem;
`

export const TutorialText = styled.p`
  color: #484648;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; 
`;

export const ContainerOTP = styled.div`
  width: 100%;
  padding: 40px 0;
`

export const InputOTPStyled = styled.input`
  display: flex;
  padding: 10px;

  height: 62px;

  flex-direction: column;
  align-items: flex-start;

  gap: 10px;
  flex: 1 0 0;

  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #E1E1E1;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; 
  }

  &[type=number] {
      -moz-appearance:textfield; 
  }
`;

export const TimeRemainingCode = styled.p`
  color: #666;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  line-height: 150%; 

  cursor: pointer;

  margin: 25px 0 0;
`

export const ContainerButton = styled.div`
  text-align: center;
  justify-content: center;
  display: flex;

  margin-top: 1rem;

  button {
    max-width: 218px;
    width: 100%;
    padding: 12px 24px;
  }
`

export const SendCodeAgain = styled.p`
  color: #005B8E;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  text-align: center;
 
  cursor: pointer;

  margin: 25px 0 0;
`

export const ForgotCellphone = styled.p`
  color: #005B8E;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  text-align: center;
  display: block;
 
  cursor: pointer;
  margin: 5px 0 0;
`

export const RemainingAttempts = styled.p`
  text-align: center;
`