import { baseURL, cdnURL } from '..';
import { EventType } from './types';

export const getEventsFromJson = async(): Promise<EventType[]> => {
  try{
    const response = await cdnURL.get(`assets/api/nextEvents.json?h=${Date.now()}`);
    if(response.status !== 200){
      throw new Error("Not found");
    }
    return response.data;
  } catch (error: any) {
    throw error.response;
  }
}

export const getEventsFromApi = async (): Promise<EventType[]> => {
  try {
    const response = await baseURL.get('api/v3/nextEvents');
    return response.data;
  } catch (error: any) {
    throw error.response;
  }
};

export const getEvents = async (): Promise<EventType[]> => {
  try {
    const data = await getEventsFromJson();
    if(!Array.isArray(data)){
      throw new Error("is not a array");
    }
    return data;
  } catch (error: any) {
  }
  try {
    const data = await getEventsFromApi()
    return data
  } catch (error: any) {
    throw error.response
  }
};

export const getEventGroupFromJson = async (slug: string): Promise<EventType[]> => {
  try {
     const response = await cdnURL.get(`assets/api/eventgroup/${slug}.json`)
    if (response.status !== 200) {
      throw new Error('Not found')
    }
    return response.data
  } catch (error: any) {
    throw error.response
  }
}

export const getEventGroupFromApi = async (slug: string): Promise<EventType[]> => {
  try {
    const response = await baseURL.get(`/api/eventgroup/${slug}`)
    return response.data
  } catch (error: any) {
    throw error.response
  }
}

export const getEventGroup = async (slug: string): Promise<EventType[]> => {
  try {
    const data = await getEventGroupFromJson(slug);
    if(!Array.isArray(data)){
      throw new Error("is not a array");
    }
    return data;
  } catch (error: any) {
  }
  try {
    const data = await getEventGroupFromApi(slug)
    return data
  } catch (error: any) {
    throw error.response
  }
}

export const searchEvents = async (text : string): Promise<EventType[]> => {

  const data  = {term: text}
  try {
    const response = await baseURL.post('api/v2/searchEvents', data);

    return response.data;
  } catch (error: any) {
    throw error.response;
  }
};

export const getPopularEvents = async (): Promise<EventType[]> => {

  try {
    const response = await baseURL.get('api/v2/PopularEvents');
    return response.data;
  } catch (error: any) {
    throw error.response;
  }
};
