import { Modal } from "components/Modal";
import { Props } from "./types";
import {
  Container,
  ContainerOTP,
  Title,
  InputOTPStyled,
  TimeRemainingCode,
  ContainerButton,
  TutorialText,
  SendCodeAgain,
  ForgotCellphone,
} from "./style";
import OtpInput from "react-otp-input";
import { useEffect, useState } from "react";
import { Button } from "components/Button";
import { useCounterToTime } from "hooks/useConterToTime";
import {
  getSMSVerificationCode,
  sendSMSVerificationCode,
  verifySMSCode,
} from "services/user/requests";
import { toast } from "react-toastify";
import { MessageSMSCode } from "services/user/types";
import { useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const MAX_LENGTH_INPUT_NUMBER = 6;

const VerificationSMSCode = ({ visible, renew, setVisible, token }: Props) => {
  const [otp, setOtp] = useState("");
  const { timer, setFinalTime, timerInSeconds } = useCounterToTime();
  const [message, setMessage] = useState<MessageSMSCode>();
  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);

  const callSendCode = async () => {
    try {
      setLoading(true);
      await sendSMSVerificationCode(token);
      const res = await getSMSVerificationCode(token);
      setFinalTime(new Date(res?.expiredIn));

      setMessage(res);
    } catch (error) {
      toast.error("Não foi possível enviar o sms");
    } finally {
      setLoading(false);
    }
  };

  const getting = async (sendAgain = true) => {
    try {
      const res = await getSMSVerificationCode(token);
      setFinalTime(new Date(res?.expiredIn));

      setMessage(res);
    } catch (error) {
      if (sendAgain) {
        callSendCode();
      } else {
        setMessage(undefined);
        setFinalTime(new Date());
      }
    }
  };

  const tryValidateCode = async () => {
    try {
      setLoading(true);
      await verifySMSCode(otp, token);
      await renew(token);

      setVisible(false);

      toast.success("Telefone validado com sucesso!");

      const redirect = searchParams.get("redirect");
      const url = redirect ? `/${redirect}` : "/";
      window.location.replace(url);
    } catch (error) {
      const decremet = (message?.verifyAttempts?.actual as number) - 1;
      if (message?.verifyAttempts && decremet < 1) {
        toast.error(
          "Você excedeu o limite de tentativas. Solicite um código novo para tentar novamente"
        );
      } else {
        toast.error("Código enviado é inválido!");
      }

      getting(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getting();
  }, []);

  const isValidLengthCode = () => otp?.length === MAX_LENGTH_INPUT_NUMBER;

  return (
    <Modal>
      <form
        style={{ padding: "2rem", zIndex: 999 }}
        className={`q2c-modal ${visible ? "is-active" : ""}`}
      >
        <div className="q2c-modal__overlay"></div>

        <Container>
          <Title>Verificação de cadastro</Title>

          <TutorialText>
            Digite o código de {MAX_LENGTH_INPUT_NUMBER} números que foi enviado
            para o seu telefone.
          </TutorialText>

          <ContainerOTP>
            <OtpInput
              containerStyle={{ gap: "0.5rem" }}
              value={otp}
              onChange={setOtp}
              numInputs={MAX_LENGTH_INPUT_NUMBER}
              inputType="number"
              renderInput={(props) => <InputOTPStyled {...props} />}
            />
            {typeof timerInSeconds === "number" && timerInSeconds > 0 ? (
              <TimeRemainingCode>
                Enviar código novamente em <span>{timer}</span>
              </TimeRemainingCode>
            ) : (
              <SendCodeAgain onClick={callSendCode}>
                {loading && (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    className="q2l-signIn-form__button_loading"
                    spin={true}
                  />
                )}{" "}
                Enviar código novamente
              </SendCodeAgain>
            )}

            <ContainerButton>
              <Button
                type="submit"
                disabled={loading || !isValidLengthCode()}
                onClick={tryValidateCode}
              >
                {loading && (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    className="q2l-signIn-form__button_loading"
                    spin={true}
                  />
                )}
                Verificar{" "}
              </Button>
            </ContainerButton>
          </ContainerOTP>

          <ForgotCellphone
            onClick={() => (window.location.href = "/dados-da-conta")}
          >
            Digitei o telefone errado
          </ForgotCellphone>
        </Container>
      </form>
    </Modal>
  );
};

export default VerificationSMSCode;
