import { capitalizeFirstLetter } from "./text";

export const formatDate = (date: string) => {
  if (!date) return null;

  const dateString = new Date(date);

  const week = new Intl.DateTimeFormat("pt-BR", { weekday: "short" })
    .format(dateString)
    .toLocaleUpperCase()
    .replace(".", "");

  const day = new Intl.DateTimeFormat("pt-BR", { day: "2-digit" })
    .format(dateString)
    .toLocaleUpperCase();

  const month = new Intl.DateTimeFormat("pt-BR", { month: "short" })
    .format(dateString)
    .toLocaleUpperCase();

  return `${week}, ${day} de ${month}`;
};

export const formatDateRange = (date: string): string => {
  if (!date) {
    return ''
  }

  const [startDate, endDate] = date.split(' - ')

  if (!startDate || !endDate) {
    return ''
  }

  const startDay = startDate.split(', ')[1]?.split(' de ')[0]
  const endDay = endDate.split(', ')[1]?.split(' de ')[0]

  const month = startDate.split(' de ')[1]

  if (!startDay || !endDay || !month) {
    return ''
  }

  return `${startDay} - ${endDay} de ${month}`
}

export const formatterDateCalendar = (dateString: string) => {
  const dateTime = new Intl.DateTimeFormat("pt-BR", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  const format = dateTime.format(Date.parse(dateString)) ?? "";
  return format;
};

export const formatDateEvent = (date: string) => {
  if (!date) return null;

  const dateString = new Date(date);

  const week = new Intl.DateTimeFormat("pt-BR", { weekday: "long" })
    .format(dateString)
    .toLocaleUpperCase()
    .replace(".", "");

  const day = new Intl.DateTimeFormat("pt-BR", { day: "2-digit" })
    .format(dateString)
    .toLocaleUpperCase();

  const month = new Intl.DateTimeFormat("pt-BR", { month: "long" })
    .format(dateString)
    .toLocaleUpperCase();

  const weekFormatted = capitalizeFirstLetter(week);
  const monthFormatted = capitalizeFirstLetter(month);

  return `${weekFormatted}, ${day} de ${monthFormatted}`;
};

export const formatEventDateTime = (eventDate: string, eventHours: string) => {
  const optionsDate: Intl.DateTimeFormatOptions = {
    weekday: "short",
    day: "numeric",
    month: "short",
    year: "numeric",
  };

  const date = new Date(eventDate);

  const formattedDate = date.toLocaleDateString("pt-BR", optionsDate);

  const capitalizedDate = formattedDate
    .split(" ")
    .map((word) =>
      word !== "de" ? word?.charAt(0)?.toUpperCase() + word.slice(1) : word
    )
    .join(" ");

  return `${capitalizedDate} - ${eventHours}`;
};

export function checkMobile(swiper: any) {
  let mobile = window.matchMedia("(min-width: 0px) and (max-width: 768px)");
  if (!mobile.matches) {
    swiper.destroy(false, true);
  }
}

export function convertNameToSlug(name: string) {
  name = name.replace(/^\s+|\s+$/g, "");
  name = name.toLowerCase();

  const from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  const to = "aaaaeeeeiiiioooouuuunc------";

  for (let i = 0, l = from.length; i < l; i++) {
    name = name.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  name = name
    .replace(/[^a-z0-9 -]/g, "")
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-");

  return name;
}

export const normalizeText = (text: string): string => {
  const textNormalize = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  return textNormalize.toLowerCase();
};

export const TransformToMoney = (currency: string, price: string | number) =>
  new Intl.NumberFormat("pt-br", {
    style: "currency",
    currency: currency,
  }).format(Number(price));

export const handlerPaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
  event.preventDefault();
  return false;
};

export const handlerCopy = (event: React.ClipboardEvent<HTMLInputElement>) => {
  event.preventDefault();
  return false;
};

export const cleanCpf = (cpf: string) => {
  return cpf?.replaceAll("-", "")?.replaceAll("/", "")?.replaceAll(".", "");
};
