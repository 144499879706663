import * as Yup from "yup";

export type Props = {
    visible?: boolean;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>
}

export const genderDataFormSchema = Yup.object().shape({
    gender: Yup.string().required("O gênero é obrigatório"),
});


