import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";

import { useAuth } from "../../hooks/useAuthProvider/useAuth";
import { useViewport } from "../../hooks/useResponsive/useResponsive";
import { SignInButtonProps } from "./types";

import { loginImg, userImg } from "../../assets/img";
import { isCommissioner } from "helpers/comissioner";

export function SignInButton({
  handleOpenModal,
  handleCloseMenuMobile,
}: SignInButtonProps) {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { width } = useViewport();
  const autenticated = user?.name != undefined;

  if (autenticated && user?.emailVerified === 0) {
    toast.error("Confirme seu email para adquirir seus ingressos.", {
      autoClose: false,
      toastId: "verify",
    });
  }

  const redirectToMyData = () => {
    if (!isCommissioner()) {
      navigate("/minha-conta");
    }
  };

  const breakpoint = 769;
  const registerLink = width < breakpoint ? "/cadastrar-mobile" : "/cadastrar";

  return autenticated ? (
    <div className="q2c-account">
      <img
        src={userImg}
        alt="Foto do usuário"
        className="q2c-account__user-img"
        width={36}
        height={36}
      />
      <button
        className="q2c-account__user-button"
        onMouseEnter={handleOpenModal}
        onClick={redirectToMyData}
      >
        {`Olá, ${user?.name && user?.name?.split(" ")[0]}`}
      </button>
    </div>
  ) : (
    <div className="q2c-account">
      <img
        src={loginImg}
        alt="Foto do usuário"
        className="q2c-account__user-icon"
        width={36}
        height={36}
      />

      <div className="q2c-account__content">
        <p className="q2c-account__text">
          Faça{" "}
          <Link
            to="/login"
            onClick={handleCloseMenuMobile}
            className="q2c-account__link"
          >
            Login
          </Link>{" "}
          ou
        </p>

        <p className="q2c-account__text">
          <Link
            to={registerLink}
            onClick={handleCloseMenuMobile}
            className="q2c-account__link"
          >
            cadastre-se
          </Link>
        </p>
      </div>
    </div>
  );
}
