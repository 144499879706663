import styled from "styled-components";

export const WhatsAppContainerForm = styled.div`
  .hs-button {
    display: block;
    width: 43.2rem;
    height: 4.8rem;
    background: var(--orange);
    border-radius: 0.8rem;
    font-weight: 600;
    font-size: 1.6rem;
    color: var(--white);

    transition: all 0.3s;
    cursor: pointer;

    &.is-disabled {
      background: var(--gray200);
      cursor: not-allowed;
    }

    &:hover {
      filter: brightness(0.9);
    }
  }

  .hs-submit {
    display: grid;
    place-items: center;
    width: 100%;
  }

  .legal-consent-container {
    font-size: 1.6rem;
    color: var(--gray500);

    @include responsive(1440px) {
      font-size: 1.4rem;
    }
  }

  .legal-consent-container .hs-form-booleancheckbox-display {
    span:not(.hs-form-required) {
      margin-left: 20px;
    }
  }

  .hs-form-booleancheckbox .hs-form-booleancheckbox-display {
    position: relative;
    display: flex;
    align-items: center;
  }

  .hs_firstname,
  .hs_phone,
  .hs_email {
    .hs-input {
      max-width: 95% !important;
      min-height: 35px !important;
      padding: 0 !important;
    }
  }

  .hs-form-booleancheckbox .hs-form-booleancheckbox-display .hs-input {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    appearance: none;
    border: 2px solid var(--gray400);
    border-radius: 0.4rem;
    outline: none;
    min-width: 2rem;
    height: 2rem;
    position: relative;
  }

  .hs-form-booleancheckbox .hs-form-booleancheckbox-display .hs-input::before {
    content: '';
    position: absolute;
    top: 1px;
    width: 5px;
    height: 9px;
    border-radius: 0;
    background: none;
    border-right: 2px solid var(--gray500);
    border-bottom: 2px solid var(--gray500);
    transform: rotate(45deg);
    opacity: 0;
    transition: all 300ms;
  }

  .hs-form-booleancheckbox .hs-form-booleancheckbox-display .hs-input:checked {
    border: 2px solid var(--gray500);
  }

  .hs-form-booleancheckbox .hs-form-booleancheckbox-display .hs-input:checked::before {
    opacity: 1;
  }

  .hs-form-booleancheckbox .hs-form-booleancheckbox-display .hs-input:focus {
    border: 2px solid var(--gray700);
  }

  .hs-dependent-field {
    display: flex;
    align-items: flex-start;
    margin-bottom: 3.2rem;
  }

  .hs_firstname,
  .hs_phone,
  .hs_email {
    margin-bottom: 1.6rem;

    .input {
      position: relative;
      border: 2px solid var(--gray200);
      border-radius: 0.8rem;
      height: 4.8rem;
      width: 100%;
      padding: 0 2.4rem;
      display: flex;
      align-items: center;
      transition: all 0.3s ease-in-out;

      .hs-input {
        position: absolute;

        width: calc(100% - 5.7rem);

        font-size: 1.6rem;
        line-height: 1.7rem;
        color: var(--gray500);
        background: transparent;

        @include responsive(475px) {
          font-size: 1.4rem;
        }

        &.is-error {
          color: #ff6961;
          animation: shake 300ms;

          &::placeholder {
            color: #ff6961;
          }
        }

        &::placeholder {
          color: var(--gray300);
          font-weight: normal;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        -moz-appearance: textfield;
      }
    }

    .input:hover {
      border-color: var(--gray300);
    }

    .input.is-disable {
      background-color: #dbdbdb;
    }

    .input.is-error {
      border: 2px solid #ff6961;
    }

    .input.is-error:focus-within {
      border-color: #ff6961;
    }

    .input.is-error:hover {
      border-color: rgba(255, 105, 97, 0.5);
    }

    .input:focus-within {
      border-color: var(--gray700);
    }

    label:not(.hs-error-msg) {
      display: none;
    }
  }

  .label {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .hs-form-booleancheckbox {
    font-weight: bold;

    a:not(.hs-button) {
      text-decoration: underline;
      color: #005b8e;
    }
  }

  .hs-richtext {
    font-size: 1.6rem;
    color: var(--gray500);
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
  }

  .hs-error-msg {
    font-size: 1.4rem;
    color: #ff6961;
  }

  .no-list {
    align-items: center;
    display: flex;
    margin-top: 0.8rem;
  }

  .submitted-message {
    width: 100%;
    max-width: 61.2rem;

    margin: 0 auto;
    margin-bottom: 4rem;

    font-size: 2rem;
    text-align: center;
    color: #505050;

    @include responsive(576px) {
      font-size: 1.8rem;
    }
  }

 .hs_error_rollup{ 
  .hs-main-font-element {
    display: none;
  }
 }
`

