import styled, { css } from "styled-components";
import {
  ErrorWrapperProps,
  InputProps,
  TrailingWrapperProps,
  WrapperIconsProps,
} from "./types";

export const ErrorMessage = styled.span`
  font-size: 1.4rem;
  color: #ff6961;
`;

export const ErrorIcon = styled.img`
  margin-right: 0.8rem;
`;

export const ErrorWrapper = styled.div<ErrorWrapperProps>`
  display: flex;
  align-items: center;
  margin-top: 0.8rem;
  ${(props) =>
    !props.show &&
    css`
      display: none;
    `}
`;

export const InputStyle = styled.input<InputProps>`
  position: absolute;
  width: 80%;
  font-size: 1.6rem;
  line-height: 1.7rem;
  color: var(--gray500);
  background: transparent;

  ${(props) =>
    props.leading &&
    css`
      @media (max-width: 1440px) {
        width: calc(100% - 5.7rem);
      }
      left: 5.7rem;
    `}

  @media (max-width: 475px) {
    font-size: 1.4rem;
  }

  &::placeholder {
    color: var(--gray300);
    font-weight: normal;
  }

  ${(props) =>
    props.error &&
    css`
      color: #ff6961;
      animation: shake 300ms;

      &::placeholder {
        color: #ff6961;
      }
    `}

  ${(props) =>
    props.disabled &&
    css`
      background-color: #dbdbdb;
    `}
`;

export const TrailingWrapper = styled.div<TrailingWrapperProps>`
  z-index: 2;

  ${(props) =>
    !props.show &&
    css`
      display: none;
    `}

  &:hover {
    cursor: pointer;
  }

  .__react_component_tooltip {
    width: 30rem;
  }
`;

export const LeadingIcon = styled.img``;

export const WrapperIcons = styled.span<WrapperIconsProps>`
  display: ${(props) => (props.leading !== undefined ? "flex" : "none")};
  position: relative;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
