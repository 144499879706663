import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  background-color: #005b8e;
  margin-top: 5rem;
`;

export const ContainerFooter = styled.footer`
  display: flex;
  flex-direction: column;
  max-width: 166.4rem;
  padding: 3rem 10.2rem;
  background-color: #005b8e;
  margin: 0 auto;
  gap: 60px;

  .ra-widget-reputation-content {
    height: 72px !important;
  }

  #ra-widget-reputation {
    width: auto !important;
    height: 40px !important;
    overflow: auto !important;
  }

  .ra-widget-reputation-stamp {
    height: 30px !important;
  }

  .ra-widget-reputation-text {
    font-size: 11px !important;
    margin: 2px auto 4px !important;
  }

  .ra-widget-reputation-logo {
    height: 13px !important;
    width: auto !important;
  }

  @media (max-width: 576px) {
    padding: 3rem 2rem;
  }
`;

export const HeaderFooter = styled.div`
  display: flex;
  justify-content: space-between;

  border-bottom: 1px solid #fff;
  padding-bottom: 32px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
`;

export const BrandsFooter = styled.img`
  // width: 230px;
`;

export const BrandsContainer = styled.div`
  display: flex;
  gap: 56px;
  justify-content: space-between;
   @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const SocialMediaWrapper = styled.div`
  display: flex;

  margin-top: 24px;

  gap: 16px;
  align-items: center;
`;

export const SocialMediaButton = styled.a`
  transition: filter 0.3s ease-in-out;

  &:hover {
    filter: brightness(0.8);
  }
`;

export const SocialMediaIcon = styled.img`
  width: 90%;
`;

export const BadgeInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1050px) {
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
`;

export const BadgeWrapper = styled.div`
  display: flex;
  gap: 48px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
`;

export const BadgeWithTextWrapper = styled.div`
  display: flex;
  gap: 48px;

  @media (max-width: 768px) {
    align-items: center;
  }
`;

export const BadgeWithoutTextWrapper = styled.div`
  display: flex;
  gap: 33px;

  @media (max-width: 768px) {
    align-items: center;
  }

  @media (max-width: 375px) {
    align-items: center;
    gap: 15px;
  }
`;

export const StoreDowloadsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    margin-bottom: 26px;
  }
`;

export const AppsTitle = styled.p`
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 18px */

  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const AppsWrapper = styled.div`
  display: flex;

  margin-top: 8px;
  gap: 15px;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const AppButton = styled.a`
  width: 40%;

  transition: filter 0.3s ease-in-out;

  &:hover {
    filter: brightness(0.8);
  }
`;

export const AppImg = styled.img`
  width: 100%;
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const ImageText = styled.p`
  margin-bottom: 8px;

  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
`;

export const FixImage = styled.img`
  height: 45px;
`;

export const StampsImage = styled.img`
  height: 75px;
`;

export const MenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1050px) {
    flex-direction: column;
    gap: 32px;
  }
`;

export const MenuTitle = styled.p`
  margin-bottom: 16px;

  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 18px */
`;

export const ServiceText = styled.p`
  color: #fff;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */

  width: 80%;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  @media (min-width: 1920px) {
    width: 100%;
  }
`;

export const MenuItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MenuLink = styled.a`
  color: #fff;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  transition: filter 0.3s ease-in-out;

  &:hover {
    filter: brightness(0.8);
  }
`;

export const CopyText = styled.p`
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 133%; /* 18.62px */
`;
