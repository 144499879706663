import { baseURL } from "services";
import { IUpdateMyAccount, SignInCredentials, ISignInAccount, RegisterRequestProp, MessageSMSCode } from "./types";

export const postUpdateMyAccount = async (userData: any): Promise<IUpdateMyAccount> => {
  try {
    const { data: response } = await baseURL.post(
      "api/website/updatemyaccount",
      userData
    );
    return response;
  } catch (error: any) {
    throw error?.response;
  }
};

export const signInRequest = async ({ email, password, captcha }: SignInCredentials): Promise<ISignInAccount> => {
  try {
    const response = await baseURL.post("api/v2/websiteuserlogin", { email, password }, { headers: { captcha } });
    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.Message || "Não foi possível concluir requisição");
  }
}

export const registerRequest = async ({ registerData, captcha }: RegisterRequestProp): Promise<ISignInAccount> => {
  try {
    const response = await baseURL.post(`api/registerclient`, registerData, { headers: { captcha } });
    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.Message || "Não foi possível concluir requisição");
  }
}

export const deleteAccount = async (id: number): Promise<void> => {
  await baseURL.post('api/newunsubscribe', {
    userId: id,
  })
}

const authHeader = (token?: string) => ({
  headers: {
    Authorization: `Bearer ${token}`
  }
})

export const getSMSVerificationCode = async (token?: string): Promise<MessageSMSCode> => {
  try {
    const response = await baseURL.get("api/v2/verify-sms-message-status", {
      ...authHeader(token),
    });
    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.Message || "Não foi possível concluir requisição");
  }
}

export const sendSMSVerificationCode = async (token?: string): Promise<{ expiredIn: string }> => {
  try {
    const response = await baseURL.post("api/v2/send-sms-code", null, {
      ...authHeader(token),
    });
    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.Message || "Não foi possível concluir requisição");
  }
}

export const verifySMSCode = async (code: string, token?: string): Promise<void> => {
  try {
    const response = await baseURL.post("api/v2/match-code", { code }, {
      ...authHeader(token)
    });
    return response.data;
  } catch (error: any) {
    throw new Error(error?.response?.data?.Message || "Não foi possível concluir requisição");
  }
}
