import { SignInForm } from "./SignInForm";

export function SignIn() {
  return (
    <div className="q2l-signIn">
      <div className="q2l-signIn__form">
        <SignInForm />
      </div>
    </div>
  )
}