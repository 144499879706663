import { Header } from "layouts/Header";
import { ReactNode } from "react";
import { WrapperContent } from "./styles";
import { NewFooter } from "layouts/NewFooter";

type CommissionersThemeProps = {
  children: ReactNode;
}

export function CommissionersTheme({ children }: CommissionersThemeProps) {
  return (
    <>
      <Header />
        <WrapperContent>
          {children}
        </WrapperContent>
      <NewFooter />
    </>
  );
}