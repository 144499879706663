import { getLocalCommissioner } from "helpers/comissioner";
import { createContext, useEffect, useState } from "react";
import { getComissioner } from "services/Comissioners/request";
import {
  CommissionerContextType,
  CommissionerProviderProps,
  CommissionerType,
} from "./types";

export const CommissionerContext = createContext<CommissionerContextType>(
  {} as CommissionerContextType
);

export function CommissionerProvider({ children }: CommissionerProviderProps) {
  const [commissioner, setCommissioner] = useState<CommissionerType>();

  const getComissarioLocal = () => {
    const localInfo = getLocalCommissioner();
    setCommissioner(localInfo);
  };

  const saveComissionerData = (data: CommissionerType) => {
    if(typeof window !== "undefined"){
      window.localStorage.setItem("commissioner", JSON.stringify(data));
    }
  };

  const removeComissionerData = () => {
    if(typeof window !== "undefined"){
      window.localStorage.removeItem("commissioner");
    }
  };

  const getComissarioUrl = async (): Promise<boolean> => {
    let params = new URL(window.location.href).searchParams;
    const id = params.get("id");
    if (id) {
      try {
        const data = await getComissioner(id);
        setCommissioner({ ...data, id: id });
        await saveComissionerData({ ...data, id: id });
        return true;
      } catch (e) {
        removeComissionerData();
      }
    }

    return false;
  };

  const initiate = async () => {
    const comissarioUrl = await getComissarioUrl();
    if (!comissarioUrl) {
      getComissarioLocal();
    }
  };

  useEffect(() => {
    initiate();
  }, []);

  return (
    <CommissionerContext.Provider value={{ commissioner }}>
      {children}
    </CommissionerContext.Provider>
  );
}

export default CommissionerProvider;