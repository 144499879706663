import styled from "styled-components";
import { BarProps } from "./types";

export const Wrapper = styled.div`
  border-radius: 8px;
  height: 250px;
  width: 300px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 2.2px rgba(0,0,0,.008), 0 0 5.3px rgba(0,0,0,.012), 0 0 10px rgba(0,0,0,.015), 0 0 17.9px rgba(0,0,0,.018), 0 0 33.4px rgba(0,0,0,.022), 0 0 80px rgba(0,0,0,.03);
`;

export const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

export const BarWrapper = styled.div`
  padding-top: 18px;
  padding-bottom: 24px;
  display: flex;
  gap: 5px;
`;
export const Bar = styled.div<BarProps>`
  background-color: ${props => props.activate ? "#20B038" : "#DFDDE1"};
  height: 2px;
  border-radius: 2px;
  width: 100%;
`;
export const Text = styled.div`
  font-size: 16px;
  padding-bottom: 10px;
`;
export const CheckIcon = styled.img`

`;

export const List = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
`;

export const ListItem = styled.div`
  gap: 5px;
  font-size: 16px;
  display: flex;
  align-items: center;
`;

export const Bullet = styled.img`
`;