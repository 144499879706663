import axios from "axios";
import { BASE_URL, CDN_URL } from "constant";
import { normalizeText } from "helpers";
import { getSessionId, setSessionIdLocalStorage } from "./sessionId/requests";

import { getUserLocalStorage } from "hooks/useAuthProvider/util";
// import { GetEventInLocalStorage } from "./eventTicket/helper";
const userToken = getUserLocalStorage();

export const baseURL = axios.create({
  baseURL: BASE_URL,
  headers: { Authorization: `Bearer ${userToken?.token}` }
});

export const cdnURL = axios.create({
  baseURL: CDN_URL,
  responseType: 'json',
  headers: {"Accept": "application/json;"}  
});

export const IBGEUrl = axios.create({
  baseURL: "https://servicodados.ibge.gov.br/",
});

export const getApiVersionForTickets = () => {
  return "v3";

  // const { useTicketLots } = GetEventInLocalStorage();
  // return useTicketLots ? "v3" : "v2"
} 

export const getApiVersionForCheckout = () => {
  return "v2/";

  // const { useTicketLots } = GetEventInLocalStorage();
  // return useTicketLots ? "v2/" : ""
}

baseURL.interceptors.response.use((response) => {
  const messageResponse = response?.data?.message;
  if (messageResponse) {
    const errorMessage = normalizeText("O carrinho não foi encontrado");

    if (normalizeText(messageResponse?.toLowerCase())?.includes(errorMessage)) {
      getSessionId().then((sessionId) => {
        setSessionIdLocalStorage(sessionId);
      });
    }
  }

  return response;
});
