import { Strong, Wrapper } from "./style"
import { useCart } from "hooks/useCart/useCart"
import { useCounterToTime } from "hooks/useConterToTime"
import { useEffect } from "react";
import { ComponentProps } from "./types";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ExpirationCartTimer = ({size, center}: ComponentProps) => {
  const { cart, clearCart } = useCart();
  const navigate = useNavigate();

  const { timer, setFinalTime, timeRunning } = useCounterToTime();

  useEffect(() => {
    if(cart?.exp){
      const cartExpire = new Date(cart?.exp);
      setFinalTime(cartExpire)
    }
  }, [cart]);

  useEffect(() => {
    if(timeRunning === false){
      clearCart();
      toast.error("Carrinho expirado.")
      navigate("/");
    }
  }, [timeRunning])
  
  
  return cart?.exp ? (
    <Wrapper size={size || 18} center={center}>
      Seu carrinho expira em <Strong>&nbsp;{timer} minutos</Strong>
    </Wrapper>
    ) : null
}

export default ExpirationCartTimer