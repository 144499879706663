import { getLocalCommissioner } from "helpers/comissioner";
import { baseURL, cdnURL } from "..";
import { EventsTicketsData } from "./types";

export const getSectionEventTickets = async (
  idSection: number
): Promise<EventsTicketsData> => {
  try {
    const response = await baseURL.get(
      `api/controlledplaces/getsectiontickets/${idSection}`
    );
    return response.data;
  } catch (error: any) {
    throw error.response;
  }
};

export const getEventTicketsBySlugFromJson = async (
  eventSlug: string
): Promise<EventsTicketsData> => {
  try{
    const path = `assets/api/getEventTicketsBySlug/${eventSlug}.json?h=${Date.now()}`;
    const response = await cdnURL.get(path);
    if(response.status !== 200){
      throw new Error("Not found");
    }
    return response.data;
  } catch (error: any) {
    throw error.response;
  }
};

export const getEventTicketsBySlugFromApi = async (
  eventSlug?: string
): Promise<EventsTicketsData> => {
  try {
    const token: string = "56f869f6c051130a44cffbbc1c304525";

    const response = await baseURL.post("api/v2/getEventTicketsBySlug", {
      token,
      eventSlug: eventSlug,
    });

    if (response.data.status !== "success")
      throw new Error("Error in requisition");

    return response.data;
  } catch (error: any) {
    throw error.response;
  }
};

export const getEventTicketsBySlug = async (
  eventSlug: string
): Promise<EventsTicketsData> => {
  
  try {
    const data = await getEventTicketsBySlugFromJson(eventSlug);
    return data;
  } catch (error: any) {
  }
  try {
    const data = await getEventTicketsBySlugFromApi(eventSlug);
    return data;
  } catch (error: any) {
    throw error.response;
  }
};


export const getCommissionerEventTicketsBySlugApi = async (
  eventSlug?: string
): Promise<EventsTicketsData> => {
  try {
    const token: string = "56f869f6c051130a44cffbbc1c304525";
    const commissionerId = getLocalCommissioner()?.id;
    if (commissionerId == null) {
      throw new Error("Comissário não encontrado");
    }
    const response = await baseURL.post(
      "api/v3/getCommissionerEventTicketsBySlug",
      {
        token,
        eventSlug,
        commissionerId,
      }
    );

    if (response.data.status !== "success")
      throw new Error("Error in requisition");

    return response.data;
  } catch (error: any) {
    throw error.response;
  }
};

export const getCommissionerEventTicketsBySlugJson = async (
  eventSlug: string
): Promise<EventsTicketsData> => {
  try{
    const commissionerId = getLocalCommissioner()?.id;
    if (commissionerId == null) {
      throw new Error("Comissário não encontrado");
    }
    const path = `assets/api/GetCommissionerEventTicketsBySlug/${commissionerId}/${eventSlug}.json?h=${Date.now()}`;
    const response = await cdnURL.get(path);
    if(response.status !== 200){
      throw new Error("Not found");
    }
    return response.data;
  } catch (error: any) {
    throw error.response;
  }
};

export const getCommissionerEventTicketsBySlug = async (
  eventSlug: string
): Promise<EventsTicketsData> => {
  try {
    const data = await getCommissionerEventTicketsBySlugJson(eventSlug);
    return data;
  } catch (error: any) {
  }
  try {
    const data = await getCommissionerEventTicketsBySlugApi(eventSlug);
    return data;
  } catch (error: any) {
    throw error.response;
  }
};
