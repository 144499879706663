import styled from "styled-components";
import { ErrorWrapperProps } from "./types";
import { chevronRegisterImg } from "assets/img";

export const Wrapper = styled.div``;

export const ErrorWrapper = styled.div<ErrorWrapperProps>`
  display: ${(props) => (props.hasError ? "flex" : "none")};
  align-items: center;
  margin-top: 0.8rem;
`;

export const ErrorMessage = styled.span`
  font-size: 1.4rem;
  color: #ff6961;
`;

export const ErrorIcon = styled.img`
  margin-right: 0.8rem;
`;

export const TrailingIcon = styled.img.attrs({
  src: chevronRegisterImg,
})``;
