import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useAuth } from "../../hooks/useAuthProvider/useAuth";
import { useNavigate } from "react-router-dom";

interface Props {
  children: React.ReactNode;
}

const MiddlewareCityState = ({ children } : Props) : JSX.Element => {

  const { loadUserDataFromToken } = useAuth();
  const navigate = useNavigate();

  useEffect(()=>{
    let user = loadUserDataFromToken();

    if(user?.cityId == null || user?.cityId == 0 || user.stateId == null || user.stateId == 0){
      toast.warning("Para finalizar sua compra, complete o seu cadastro.");
      navigate("/dados-da-conta");
    }
  }, [])

  return <>{children}</>;
}

export default MiddlewareCityState;
