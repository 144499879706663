import { REACT_APP_CDN_IMG } from "constant";
import styled from "styled-components";
import { HomeLinkProps } from "./types";

export const Logo = styled.img.attrs({
  src: `${REACT_APP_CDN_IMG}/fit-in/186x129/filters:format(webp):quality(100)/img/website-images/q2logo.png`,
  alt: "Logo Q2 Ingressos",
})`
  @media (max-width: 475px) {
    width: 6.2rem;
  }

  max-width: inherit;
  width: 7.1rem;
  height: 100%;
`;

export const HomeLink = styled.a<HomeLinkProps>`
  @media (max-width: 475px) {
    margin-right: ${(props) => {
      return props.addspacing ? "0" : "2rem";
    }};
  }

  margin-right: ${(props) => {
      return props.addspacing ? "0" : "5rem";
    }};
`;