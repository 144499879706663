import { getLocalCommissioner } from "helpers/comissioner";
import { baseURL, cdnURL } from "..";
import { CommissionersType } from "./types";
import { EventType } from "services/events/types";

export const getComissioner = async (
  id: string
): Promise<CommissionersType> => {
  try {
    const response = await baseURL.post(
      "api/Commissioners/getcommissionerinfo",
      { hash: id }
    );
    return response.data;
  } catch (error: any) {
    throw error.response;
  }
};

export const getticketeventlistApi = async (
  id: string
): Promise<EventType[]> => {
  try {
    const response = await baseURL.post(
      "/api/v2/CommissionersLandingPage/getticketeventlist",
      {
        token: process.env.REACT_APP_COMISSARIOS_TOKEN,
        commissionerID: id,
      }
    );
    return response.data.events;
  } catch (error: any) {
    throw error.response;
  }
};

export const getticketeventlistJson = async (
  id: string
): Promise<EventType[]> => {
  try{
    const response = await cdnURL.get(`assets/api/CommissionersLandingPage/getticketeventlist/${id}.json?h=${Date.now()}`);
    if(response.status !== 200){
      throw new Error("Not found");
    }
    return response.data;
  } catch (error: any) {
    throw error.response;
  }
};

export const getticketeventlist = async (
  id: string
): Promise<EventType[]> => {
  try {
    const data = await getticketeventlistJson(id);
    if(!Array.isArray(data)){
      throw new Error("is not a array");
    }
    return data;
  } catch (error: any) {
  }
  try {
    const data = await getticketeventlistApi(id);
    return data;
  } catch (error: any) {
    throw error.response;
  }
};

export const getCommissionedEventTickets = async (id?: number) => {
  try {
    const commissioner = getLocalCommissioner();

    if (!commissioner?.id) {
      throw new Error("Error in requisition, commissioner not found");
    }

    const response = await baseURL.post(
      "/api/CommissionersLandingPage/geteventtickets",
      {
        token: process.env.REACT_APP_COMISSARIOS_TOKEN,
        eventId: id,
        commissionerID: commissioner.id,
      }
    );

    if (response.data.status !== "success")
      throw new Error("Error in requisition");

    return response.data;
  } catch (error: any) {
    throw error.response;
  }
};

export const getEventGroupApi = async (slug:string, id: string): Promise<EventType[]> => {
  try {
    const response = await baseURL.get(`/api/eventgroup/${id}/${slug}`, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_COMISSARIOS_TOKEN}`,
        commissionerID: id,
      },
    })
    return response.data
  } catch (error: any) {
    throw error.response
  }
}

export const getEventGroupJson = async (slug:string, id: string): Promise<EventType[]> => {
  try {
    const response = await cdnURL.get(`/api/eventgroup/${id}/${slug}.json`)
    if (response.status !== 200) {
      throw new Error('Not found')
    }
    return response.data
  } catch (error: any) {
    throw error.response
  }
}

export const getEventGrouplist = async (slug:string, id: string): Promise<EventType[]> => {
  try {
    const data = await getEventGroupJson(slug, id)
    if (!Array.isArray(data)) {
      throw new Error('is not a array')
    }
    return data
  } catch (error: any) {}
  try {
    const data = await getEventGroupApi(slug, id)
    return data
  } catch (error: any) {
    throw error.response
  }
}
