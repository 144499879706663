import { ReactNode } from "react";
import { Header } from "../../layouts/Header";
import { NewFooter } from "layouts/NewFooter";

type WebsiteThemeProps = {
  children: ReactNode;
  hasFooter?: boolean;
};

export function WebsiteTheme({ children, hasFooter = true }: WebsiteThemeProps) {
  return (
    <>
      <Header />
      {children}
      {hasFooter && <NewFooter />}
     
    </>
  );
}
