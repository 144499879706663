import { EventsTicketsData } from "./types";

export const SetEventInLocalStorage = (event: EventsTicketsData) => {
  if(typeof window !== "undefined"){
    window.localStorage.setItem("EventInCart", JSON.stringify(event));
  }
}
  
export const GetEventInLocalStorage = ():EventsTicketsData => {
  const event = typeof window !== "undefined" ? window.localStorage.getItem("EventInCart") : null;
  return JSON.parse(event || "{}");
}
  