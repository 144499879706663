import { StrongCheck, Bullet as BulletImage } from "assets/img";
import { Bar, BarWrapper, Title, Wrapper, Text, List, ListItem, Bullet } from "./styles";
import { Props } from "./types";



const StrongPassword = ({password}: Props) => {

  const minimalSize = password?.length >= 8 ? 1 : 0;
  const letter = /[A-Z]/.test(password) && /[a-z]/.test(password) ? 1 : 0;
  const number = /\d/.test(password) ? 1 : 0;
  const symbol = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password) 
  && password?.length > 0 ? 1 : 0;
  
  
  const minimalSizeBullet = minimalSize == 1 ? StrongCheck : BulletImage;
  const letterBullet = letter == 1 ? StrongCheck : BulletImage;
  const numberBullet = number == 1 ? StrongCheck : BulletImage;
  const symbolBullet = symbol == 1 ? StrongCheck : BulletImage;
  
  const score = minimalSize + letter + number + symbol;
  const title = score == 0 ? "Crie sua senha" : 
                score == 1 ? "Senha fraca" : 
                score == 2 ? "Estamos quase lá" : "Senha forte";

  return (
    <Wrapper>
      <Title>
        {title}
      </Title>
      <BarWrapper>
        <Bar activate={score > 0}/>
        <Bar activate={score > 1}/>
        <Bar activate={score > 2}/>
        <Bar activate={score > 3}/>
      </BarWrapper>
      <Text>
        Deve conter:
      </Text>
      <List>
        <ListItem>
          <Bullet src={minimalSizeBullet}/> Mínimo de 8 caracteres
        </ListItem>
        <ListItem>
          <Bullet src={letterBullet}/> Letras maiúsculas e minúsculas
        </ListItem>
        <ListItem>
          <Bullet src={numberBullet}/> Números
        </ListItem>
        <ListItem>
          <Bullet src={symbolBullet}/> Um símbolo (#$&*)
        </ListItem>
      </List>
    </Wrapper>
  )
}

export default StrongPassword;