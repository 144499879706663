import ReactTooltip from "react-tooltip";
import { IconWrapper, InfoIcon } from "./styles";
import { TooltipProps } from "./types";

const Tooltip = ({ message, id, icon, ...props }: TooltipProps) => {
  return (
    <>
      <IconWrapper data-tip data-for={id}>
        <InfoIcon
          src={icon}
          alt="Icone de informação"
        />
      </IconWrapper>

      <ReactTooltip id={id} type="light" effect="solid" {...props}>
        <span dangerouslySetInnerHTML={{ __html: message }} />
      </ReactTooltip>
    </>
  );
};

export default Tooltip;
