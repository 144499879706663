import { useRef, useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";

import { useAuth } from "../../hooks/useAuthProvider/useAuth";
import { SignInButton } from "../../components/SignInButton";
import { Modal } from "../../components/Modal";

import {
  menuImg,
  notificationImg,
} from "../../assets/img";

import SearchBar from "components/SearchBar";
import { useCart } from "hooks/useCart/useCart";
import { CartButton, CartButtonMobile, CartButtonWrapper, CartClock, CartImg, IdentificationWrapper, LogoutButton, LogoutButtonImg, LogoutButtonText, LogoutButtonWrapper, ModalPaymentWrapper, ModalSair, ShowModal } from "./styles";
import ModalPaymentDetail from "components/ModalPaymentDetail";
import { useNavigate } from "react-router-dom";
import HeaderLogo from "./components/HeaderLogo";

const WebsiteHeader = () => {
  const refPaymentModal = useRef<HTMLDivElement>(null);
  const refHeader = useRef<HTMLElement>(null);
  const { isAuthenticated, logout } = useAuth();
  const navigate = useNavigate();
  const { clearCart, quantityItemInCart } = useCart();
  const [showModalPayment, setShowModalPayment] = useState(false);
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const handleOpenMenuMobile = () => {
    document.documentElement.classList.add("is-menu-opened");
  };

  const addShadow = () => {
    if (window.pageYOffset < 80) {
      refHeader.current?.classList.remove("is-active-shadow");
    }

    if (window.pageYOffset > 80) {
      refHeader.current?.classList.add("is-active-shadow");
    }
  };

  const paymentModalHandler = () => {
    if(quantityItemInCart){
      setShowModalPayment(!showModalPayment);
    }else{
      setShowModalPayment(false);
    }
  }

  const functionLogout = () => {
    clearCart();
    logout()
  }

  useEffect(() => {

    function handleClickOutside(event:any) {
      if (refPaymentModal.current && !refPaymentModal.current.contains(event.target)) {
        setShowModalPayment(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [refPaymentModal]);

  document.addEventListener("scroll", addShadow);

  const handleOpenModal = () => {
    setOpenLogoutModal(!openLogoutModal)
  };

  useEffect(() => {
    if(quantityItemInCart === 0){
      setShowModalPayment(false);
    }
  }, [quantityItemInCart]);

  return (
      <>
        <section className="q2l-header__section">
          <HeaderLogo/>
          <nav className="q2l-header__nav">
            <ul className="q2l-header__list">
              <li className="q2l-header__item">
                <a
                  href="/"
                  className={
                    window.location.pathname === "/" ? "q2l-header__link is-active" : "q2l-header__link"
                  }
                >
                  Home
                </a>
              </li>
              {isAuthenticated() && (
                <>
                  <li className="q2l-header__item">
                    <NavLink
                      to="/meus-ingressos"
                      className={({ isActive }) =>
                        isActive ? "q2l-header__link is-active" : "q2l-header__link"
                      }
                    >
                      Meus Ingressos
                    </NavLink>
                  </li>

                  <li className="q2l-header__item">
                    <NavLink
                      to="/minha-conta"
                      className={({ isActive }) =>
                        isActive ? "q2l-header__link is-active" : "q2l-header__link"
                      }
                    >
                      Minha Conta
                    </NavLink>
                  </li>
                </>
              )}
            </ul>
          </nav>
        </section>

        <section className="q2l-header__section has-align-end">
          <div className="q2l-header__search">
            <SearchBar />
          </div>

          <IdentificationWrapper>
            <SignInButton handleOpenModal={handleOpenModal} />

            <Modal>
              <ModalSair open={openLogoutModal}>
                <ShowModal onClick={handleOpenModal}></ShowModal>
                <LogoutButtonWrapper>
                  <LogoutButton
                    onClick={() => functionLogout()}
                    onMouseLeave={handleOpenModal}
                  >
                    <LogoutButtonImg/>
                    <LogoutButtonText>
                      Sair
                    </LogoutButtonText>
                  </LogoutButton>
                </LogoutButtonWrapper>
              </ModalSair>
            </Modal>
          </IdentificationWrapper>

          {isAuthenticated() && (
            <div className="q2l-header__notification">
              <Link to="#" className="q2l-header__notification-link"  aria-label="notificacao">
                <img
                  src={notificationImg}
                  alt="Icone de Notificacoes"
                  className="q2l-header__notification-icon"
                />
                <span className="q2l-header__notification-badge">2</span>
              </Link>
            </div>
          )}
          <div className="q2l-header__old-ios-fix"></div>
          <div className="q2l-header__cart">
            <CartButtonWrapper>
              <CartButton onClick={paymentModalHandler}>
                <CartImg/>
                {!!quantityItemInCart && (
                  <CartClock />
                )}
              </CartButton>
              {showModalPayment && (
                <ModalPaymentWrapper ref={refPaymentModal}>
                  <ModalPaymentDetail />
                </ModalPaymentWrapper>
              )}
            </CartButtonWrapper>
            <CartButtonMobile onClick={()=>{navigate("/carrinho")}}>
              <CartImg/>
              {!!quantityItemInCart && (
                <CartClock />
              )}
            </CartButtonMobile>
          </div>

          <button
            type="button"
            className="q2l-header__toggle-menu"
            onClick={handleOpenMenuMobile}
          >
            <img
              src={menuImg}
              width={36}
              height={36}
              alt="Icone menu mobile q2l-header__toggle-menu-icon"
            />
          </button>
        </section>
      </>
  );
}

export default WebsiteHeader;