import { createContext, useEffect, useState } from "react";
import { toast } from "react-toastify";

import { getCities, getStates } from "../../services/IBGE/requests";
import { IBGECities, IBGEStates } from "../../services/IBGE/types";
import { IBGEContextType, IBGEProviderProps } from "./types";

export const IBGEContext = createContext<IBGEContextType>(
  {} as IBGEContextType
);

export function IBGEProvider({ children }: IBGEProviderProps) {
  const [states, setStates] = useState<IBGEStates[]>([]);
  const [cities, setCities] = useState<IBGECities[]>([]);

  const showStates = async () => {
    try {
      const response = await getStates();

      setStates(response);
    } catch (error) {
      toast.error("Erro ao carregar estados")
    }
  };

  const showCities = async (stateId?: string) => {
    if(stateId){
      try {
        const response = await getCities(stateId);
        setCities(response);
      } catch (error) {
        toast.error("Erro ao carregar cidades")
      }
    }
  };

  useEffect(() => {
    showStates();
    showCities();
  }, []);

  return (
    <IBGEContext.Provider value={{ states, cities, showCities }}>
      {children}
    </IBGEContext.Provider>
  );
}
