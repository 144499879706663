import VerificationSMSCode from "components/VerificationSMSCode";
import { useAuth } from "hooks/useAuthProvider/useAuth";
import { useEffect, useState } from "react";
import { Options } from "types";

export const ModalVerificationPhoneNumber = () => {
  const [visible, setVisible] = useState(true);
  const { renew, user, token } = useAuth();

  const [isNeedVerification, setIsNeedVerification] = useState(false);

  useEffect(() => {

    const isEnabledPage = window.location.pathname !== "/dados-da-conta"

    setIsNeedVerification(user?.isPhoneVerified === Options.FALSE && !!token && isEnabledPage);
  }, [user, token, window.location.pathname]);

  return isNeedVerification ? (
    <VerificationSMSCode
      renew={renew}
      token={token}
      setVisible={setVisible}
      visible={visible}
    />
  ) : null;
};
