import styled from "styled-components";

type ButtonProps = {
  bgColor?: string;
  color?: string;
  outline?: boolean;
  loading?: boolean;
  width?: string;
  height?: string;
  disabled?: boolean;
}

export const Button = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  max-width: 100%;
  width: ${({ width }) => width};
  height: ${({ height }) => height};

  padding: 0 16px;

  background-color: ${({ bgColor, outline, disabled, loading }) => {
    if (disabled) return "rgba(204, 204, 204, 0.8)";
    if (!outline && !loading) return bgColor;
    return "transparent";
  }};

  border: ${({ bgColor, outline, disabled, loading }) => 
    !disabled && !loading && outline ? `1px solid ${bgColor}` : "none"
  };
  border-radius: 6px;

  cursor: ${({ loading, disabled }) => loading || disabled ? "not-allowed" : "pointer"};

  transition: all .3s ease-in-out;

  &:hover {
    filter: brightness(0.8);
  }

  @media (max-width: 478px) {
    padding: 0 8px;
  }
`;

export const ButtonIcon = styled.img`
  width: 20px;
  height: 20px;

  margin-right: 8px;
`;

export const ButtonText = styled.span<ButtonProps>`
  font-family: Work Sans;
  font-size: 16px;
  font-weight: 600;
  text-align: center;

  color: ${({ color, outline, bgColor }) => outline ? bgColor : color};
`;

