import styled from "styled-components";

export const Container = styled.div`
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);
  z-index: 999;
  padding: 24px;

  max-width:  672px;
  width:100%;

  max-height: 300px;
  height: 80%;

  @media (max-width: 425px) {
    max-height: 340px
  }

  @media (max-width: 375px) {
    max-height: 380px
  }

`;

export const Title = styled.strong`
  color: #005B8E;
  font-family: Work Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; 

  margin-bottom: 0.5rem;

  display: block;
`

export const Description = styled.p`
  color: #292929;
  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  margin-top: 0.5rem;
`

export const TutorialText = styled.p`
  color: #292929;
  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; 
`;


export const ContainerButton = styled.div`
  text-align: center;
  justify-content: center;
  display: flex;

  margin-top: 1rem;

  button {
    max-width: 102px;
    width: 100%;
    height: 47px;
    padding: 12px 24px;
    margin-top: 25px;
    margin-left: 80%;
    background-color: #005B8E;
  }
`

export const InputWrapper = styled.div`
  &:not(:first-child) {
    margin-top: 1.6rem;
  }
`;
