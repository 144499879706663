import { eyesOpenImg, warningImg } from "assets/img";
import Tooltip from "components/Tooltip";
import { handleKeyDownMask, handleKeyUpMask } from "helpers/maks";
import { InputProps } from "../types";

export function InputNotControled({
  leadingIcon,
  trailingIcon,
  tooltipMessage,
  idTooltip,
  mask,
  isDisabled,
  error,
  messageError,
  ...props
}: InputProps) {
  if (props.type === "password") {
    props.type = props.type;
    trailingIcon = eyesOpenImg;
  }

  const errorType = messageError || error;
  const hasError = errorType ? "is-error" : "";

  return (
    <div className="q2c-text-field-container">
      <div className={`q2c-text-field ${hasError}`}>
        <span className="q2c-text-field__icons">
          <img src={leadingIcon} className="q2c-text-field__leading" />
          {tooltipMessage && (
            <div className="q2c-text-field__trailing">
              <Tooltip
                place="top"
                id={idTooltip || ""}
                message={tooltipMessage}
                icon={trailingIcon}
              />
            </div>
          )}
        </span>

        <input
          {...props}
          className={`q2c-text-field__input`}
          onKeyUp={(e) => handleKeyUpMask(e, mask)}
          onKeyDown={(e) => handleKeyDownMask(e, mask)}
          onWheel={(e) => e.currentTarget.blur()}
        />
      </div>

      {errorType && (
        <div className="q2c-text-field-helper">
          <img
            src={warningImg}
            alt=""
            className="q2c-text-field-helper__icon"
          />
          <span className="q2c-text-field-helper__message">{errorType}</span>
        </div>
      )}
    </div>
  );
}
