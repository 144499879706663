import { isCommissioner } from "helpers/comissioner";
import WebsiteHeader from "./WebsiteHeader";
import ComissionerHeader from "./ComissionerHeader";
import { HeaderContainer, HeaderWrapper } from "./styles";
import { useRef } from "react";
import { MenuMobile } from "../../components/MenuMobile";


export function Header() {
  const commissioner = isCommissioner();
  const refHeader = useRef<HTMLElement>(null);
  const addShadow = () => {
    if (window.pageYOffset < 80) {
      refHeader.current?.classList.remove("is-active-shadow");
    }

    if (window.pageYOffset > 80) {
      refHeader.current?.classList.add("is-active-shadow");
    }
  };

  document.addEventListener("scroll", addShadow);

  return (
    <HeaderWrapper ref={refHeader}>
      <HeaderContainer>
        {
          commissioner ? <ComissionerHeader /> : <WebsiteHeader />
        }
      </HeaderContainer>
      <MenuMobile />
    </HeaderWrapper>
  );
}
