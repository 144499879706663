import { useEffect, useRef, useState } from "react";
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { registerFormSchema } from "../Schemas/registerFormShema";
import { useAddress } from "../../../hooks/useAddress/useAddress";
import { toast } from "react-toastify";
import { Input } from "../../../components/Form/Input/input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import {
  calendarRegisterErrorImg,
  calendarRegisterImg,
  emailErrorImg,
  emailImg,
  eyesClodedImg,
  fingerprintRegisterErrorImg,
  fingerprintRegisterImg,
  lockErrorImg,
  lockImg,
  phoneRegisterErrorImg,
  phoneRegisterImg,
  placeRegisterErrorImg,
  placeRegisterImg,
  userRegisterErrorImg,
  userRegisterImg,
  warningImg,
} from "../../../assets/img";
import { handlerCopy, handlerPaste } from "helpers";
import StrongPassword from "components/StrongPassword";
import { ErrorIcon, ErrorMessage, ErrorWrapper, SideArrow, WrapperPassword, WrapperStrongPassword } from "./styles";
import { CaptchaAndButtonWrapper, RegisterButton } from "./styles";
import ReCAPTCHA from "react-google-recaptcha";
import { REACT_APP_RECAPTCHA } from "constant";
import { useAuth } from "hooks/useAuthProvider/useAuth";
import FormSelect from "components/Form/FormSelect";
import { genderOptions } from "utils/user";

export function RegisterForm() {
  const { states, showCitiesByStateId, cities } = useAddress();
  const { registerClient } = useAuth();
  // const [searchParams] = useSearchParams();
  const refCaptcha = useRef<ReCAPTCHA>(null);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(registerFormSchema),
    mode: "onChange",
  });

  const selectValueState = watch("state");
  const passwordWatcher = watch("password");

  const localStates = states.map((item) => ({
    label: item.Name,
    value: item.StateID,
  }));

  const localCities = cities.map((item) => ({
    label: item.Name,
    value: item.CityID,
  }));

  const [passwordFocused, setPasswordFocused] = useState(false);
  const onFocus = () => setPasswordFocused(true);
  const onBlur = () => setPasswordFocused(false);

  const handleRegisterForm: SubmitHandler<FieldValues> = async (values) => {
    let registerData = {
      name: values.name,
      password: values.password,
      email: values.email,
      birthDate: values.birthDate,
      phone: values.phoneNumber,
      cpf: values.cpf,
      gender: values.gender,
      stateID: values.state,
      cityID: values.city,
    };

    try {
      setLoading(true);

      await registerClient({ registerData, captcha: values.captcha });

      toast.success("Usuário cadastrado com sucesso, verifique seu e-mail");
      // const redirect = searchParams.get("redirect");
      // const url = redirect ? `/${redirect}` : "/";
      // window.location.href = url;
    } catch (error: any) {
      refCaptcha.current?.reset();
      toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    showCitiesByStateId(selectValueState);
  }, [selectValueState]);

  const configRecaptcha = () => {
    window.recaptchaOptions = {
      enterprise: true,
    };
  };

  const onChangeRecaptcha = (token: string | null) => {
    if (token != null) {
      setValue("captcha", token, { shouldValidate: true });
    }
  };

  const onExpired = () => {
    setValue("captcha", "", { shouldValidate: true });
  };

  return (
    <form
      onSubmit={handleSubmit(handleRegisterForm)}
      className="q2l-register-form"
    >
      <Input
        type="text"
        leadingIcon={errors.name ? userRegisterErrorImg : userRegisterImg}
        placeholder="Nome"
        {...register("name")}
        error={errors.name}
      />
      <Input
        type="text"
        leadingIcon={
          errors.phoneNumber ? phoneRegisterErrorImg : phoneRegisterImg
        }
        placeholder="Whatsapp"
        mask="phoneNumber"
        {...register("phoneNumber")}
        error={errors.phoneNumber}
        inputMode="tel"
      />
      <Input
        mask="email"
        type="text"
        leadingIcon={errors.email ? emailErrorImg : emailImg}
        placeholder="Seu e-mail"
        {...register("email")}
        error={errors.email}
        autoComplete="new-email"
        onCopy={(e) => handlerCopy(e)}
        onPaste={(e) => handlerPaste(e)}
        inputMode="email"
      />

      <WrapperPassword>
        <WrapperStrongPassword show={passwordFocused}>
          <SideArrow>▲</SideArrow>
          <StrongPassword password={passwordWatcher} />
        </WrapperStrongPassword>
        <Input
          type="password"
          leadingIcon={errors.password ? lockErrorImg : lockImg}
          trailingIcon={eyesClodedImg}
          placeholder="Crie sua senha"
          {...register("password")}
          error={errors.password}
          autoComplete="new-password"
          onCopy={(e) => handlerCopy(e)}
          onPaste={(e) => handlerPaste(e)}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </WrapperPassword>

      <Input
        type="password"
        leadingIcon={errors.confirmPassword ? lockErrorImg : lockImg}
        trailingIcon={eyesClodedImg}
        placeholder="Repita a senha"
        {...register("confirmPassword")}
        error={errors.confirmPassword}
        onCopy={(e) => handlerCopy(e)}
        onPaste={(e) => handlerPaste(e)}
      />
      <Input
        type="text"
        leadingIcon={
          errors.cpf ? fingerprintRegisterErrorImg : fingerprintRegisterImg
        }
        placeholder="CPF"
        mask="cpf"
        {...register("cpf")}
        error={errors.cpf}
        inputMode="numeric"
      />
      <Input
        type="text"
        leadingIcon={
          errors.birthDate ? calendarRegisterErrorImg : calendarRegisterImg
        }
        placeholder="Data de nascimento"
        mask="date"
        {...register("birthDate")}
        error={errors.birthDate}
        inputMode="numeric"
      />

      <div style={{ marginTop: "1.6rem" }}>
        <Controller
          control={control}
          name="gender"
          render={({ field: { onChange, value } }) => (
            <FormSelect
              value={genderOptions.find(select => select.value == value)}
              leadingImage={
                errors?.gender ? userRegisterErrorImg : userRegisterImg
              }
              placeholder="Selecione seu gênero"
              onChange={(selectValue: any) => {
                onChange(selectValue?.value);
              }}
              options={genderOptions}
              error={errors?.gender}
            />
          )}
        />
      </div>

      <div style={{ marginTop: "1.6rem" }}>
        <Controller
          control={control}
          name="state"
          render={({ field: { onChange, value } }) => (
            <FormSelect
              value={localStates.find(item => item.value == value)}
              placeholder="Seu estado"
              leadingImage={
                errors?.state
                  ? placeRegisterErrorImg
                  : placeRegisterImg
              }
              onChange={(selectValue: any) => {
                onChange(selectValue?.value);
              }}
              options={localStates}
              error={errors?.state}
            />
          )}
        />
      </div>

      <div style={{ marginTop: "1.6rem" }}>
        <Controller
          control={control}
          name="city"
          render={({ field: { onChange, value } }) => (
            <FormSelect
              value={localCities.find(item => item.value == value)}
              placeholder="Sua cidade"
              leadingImage={
                errors?.city
                  ? placeRegisterErrorImg
                  : placeRegisterImg
              }
              onChange={(selectValue: any) => onChange(selectValue?.value)}
              options={localCities}
              error={errors?.city}
            />
          )}
        />
      </div>
      <CaptchaAndButtonWrapper>
        <ReCAPTCHA
          ref={refCaptcha}
          sitekey={REACT_APP_RECAPTCHA || ""}
          onChange={onChangeRecaptcha}
          asyncScriptOnLoad={configRecaptcha}
          onExpired={onExpired}
        />

        {errors?.captcha?.message && (
          <ErrorWrapper>
            <ErrorIcon src={warningImg} alt="" />
            <ErrorMessage>
              O Captcha é obrigatório
            </ErrorMessage>
          </ErrorWrapper>
        )}

        <RegisterButton type="submit">
          {loading ? (
            <FontAwesomeIcon
              icon={faSpinner}
              className="q2l-signIn-form__button_loading"
              spin={true}
            />
          ) : null}
          Cadastrar
        </RegisterButton>
      </CaptchaAndButtonWrapper>
    </form>
  );
}