import LoadingSpinner from 'components/LoadingSpinner';

import { ButtonProps } from './types';

import * as S from './styles';

const Button = ({ 
  children, 
  outline = false, 
  width = "100%",
  height = "48px", 
  bgColor = "#005B8E", 
  color = "#FFFFFF", 
  loading = false, 
  disabled,
  icon,
  ...props 
}: ButtonProps) => {
  const COLOR_LOADING_SPINNER = outline ? bgColor : color;

  return (
    <S.Button 
      {...props} 
      bgColor={bgColor} 
      color={color} 
      outline={outline} 
      width={width}
      height={height}
      disabled={disabled || loading}
      loading={loading}
    >


     {!loading ? (
      <>
        {icon && <S.ButtonIcon src={icon} />}
        
        <S.ButtonText bgColor={bgColor} color={color} outline={outline}>
          {children}
        </S.ButtonText>
      </>
      ) : (
        <LoadingSpinner size={20} color={COLOR_LOADING_SPINNER} />
      )} 
    </S.Button>
  ); 
}

export default Button;