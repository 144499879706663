import { datadogRum } from "@datadog/browser-rum";
import { CartItems } from "services/cart/types";
import { EventsTicketsData } from "services/eventTicket/types";

export enum PaymentStatus {
    pending = 0,
    approved = 1,
    authorized = 2,
    in_process = 3,
    in_mediation = 4,
    rejected = 5,
    cancelled = 6,
    refunded = 7,
    charged_back = 8
}

export interface IGtagPurchase {
    transaction_id: string;
    value: number;
    tax: number;
    items: Array<CartItems>;
    preferenceId?: string;
}

interface IGtagItems {
    item_id: number; // Ticket_id
    item_name: string; // Ticket_name
    item_category: string; // section_name
    item_list_id: number; // event_id
    item_list_name: string; // event_name
    price: number; // item_price
    quantity: number;
    location_id?: string; // lot_id
}

interface IGtagCart {
    total: number,
    totalProcessingFee: number,
    totalTax: number,
    items: CartItems[]
}

const transformItensToEventTag = (cartTickets: CartItems[]): IGtagItems[] => {
    return cartTickets ? cartTickets.map(ticket => {
        return {
            item_id: ticket.ticketId,
            item_name: ticket.ticketName,
            price: ticket.value,
            quantity: ticket.quantity,
            item_category: ticket.sectionName,
            item_list_id: ticket.eventId,
            item_list_name: ticket.eventName,
            location_id: ticket.lotId.toString(),
        }
    }) : [];
}

const getTicketsFromSections = (event: EventsTicketsData): Omit<IGtagItems, "quantity">[] => {
    const tickets: Omit<IGtagItems, "quantity">[] = [];
    event.sections.forEach((section) => {
        section.tickets.forEach((ticket) => {
            if (ticket.id) {
                tickets.push({
                    item_id: ticket.id,
                    item_name: ticket.name,
                    price: ticket.value,
                    item_category: section.name,
                    item_list_id: event.id,
                    item_list_name: event.name,
                    location_id: ticket.lotId.toString(),
                })
            }
        })
    })
    return tickets;
}

export const GtagViewItemList = (event: EventsTicketsData) => {
    try {
        const ticketsFromEvent = getTicketsFromSections(event);
        const dataEvent = {
            item_list_name: event.name,
            items: ticketsFromEvent,
        }
        gtag("event", "view_item_list", dataEvent);
        datadogRum.addAction('view_item_list', dataEvent);
    } catch (error) {
        console.error({ errorIn: "GtagViewItemList", data: event, error });
    }
}

export const GtagAddToCart = (ticket: CartItems) => {
    try {
        const dataEvent = {
            items: transformItensToEventTag([{ ...ticket, quantity: 1 }]),
        }
        gtag("event", "add_to_cart", dataEvent);
        datadogRum.addAction('add_to_cart', dataEvent);
    } catch (error) {
        console.error({ errorIn: "GtagAddToCart", data: ticket, error });
    }
}

export const GtagRemoveFromCart = (ticket: CartItems) => {
    try {
        const dataEvent = {
            items: transformItensToEventTag([{ ...ticket, quantity: 1 }]),
        }
        gtag("event", "remove_from_cart", dataEvent);
        datadogRum.addAction('remove_from_cart', dataEvent);
    } catch (error) {
        console.error({ errorIn: "GtagRemoveFromCart", data: ticket, error });
    }
}

export const GtagViewCart = ({ total, totalProcessingFee, totalTax, items }: IGtagCart) => {
    try {
        const dataEvent = {
            currency: "BRL",
            value: total,
            items: transformItensToEventTag(items)
        }
        gtag("event", "view_cart", dataEvent);
        datadogRum.addAction('view_cart', dataEvent);
    } catch (error) {
        console.error({
            errorIn: "GtagViewCart",
            data: {
                total,
                totalProcessingFee,
                totalTax,
                items
            },
            error
        });
    }
}

export const GtagBeginCheckout = ({ total, totalProcessingFee, totalTax, items }: IGtagCart) => {
    try {
        const dataEvent = {
            currency: "BRL",
            value: total,
            items: transformItensToEventTag(items)
        }
        gtag("event", "begin_checkout", dataEvent);
        datadogRum.addAction('begin_checkout', dataEvent);
    } catch (error) {
        console.error({
            errorIn: "GtagBeginCheckout",
            data: {
                total,
                totalProcessingFee,
                totalTax,
                items
            },
            error
        });
    }
}

export const GtagPurchase = (purchase: IGtagPurchase) => {
    try {
        const dataEvent = {
            transaction_id: purchase.transaction_id,
            value: purchase.value,
            tax: purchase.tax,
            currency: "BRL",
            items: transformItensToEventTag(purchase.items),
            preference_id: purchase.preferenceId
        }
        gtag("event", "purchase", dataEvent);
        datadogRum.addAction('purchase', dataEvent);
    } catch (error) {
        console.error({ errorIn: "GtagPurchase", data: purchase });
    }
}
