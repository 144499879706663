import { useEffect, useState, FormEvent } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { searchImg } from "../../assets/img";

const SearchBar = () => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState<string>("");
  const [searchParams] = useSearchParams();

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    navigate(`/resultados?s=${searchText}`);
  };

  useEffect(() => {
    const search = searchParams.get("s");
    setSearchText(search ? search : "");
  }, []);

  const handleSearchEvent = (event: FormEvent<HTMLInputElement>) => {
    setSearchText(event.currentTarget.value);
  };

  return (
    <form className="q2c-search" onSubmit={handleSubmit}>
      <input
        type="text"
        placeholder="Pesquise aqui algum evento"
        onChange={handleSearchEvent}
        value={searchText}
        className="q2c-search-input"
      />
      <button type="submit" className="q2c-search-button">
        <img 
          src={searchImg} 
          alt="Icone de lupa" 
          className="q2c-search-icon" 
          width={25}
          height={27}
        />
      </button>
    </form>
  );
};

export default SearchBar;
