import styled from "styled-components";


export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ModalTitle = styled.h2`
  font-size: 2.4rem;
  font-weight: 600;
  color: #005b8e;
`;

export const ModalCloseButton = styled.img``;

export const ModalDescription = styled.p`
  font-size: 1.6rem;
  font-weight: 400;
  color: #292929;

  margin-top: 12px;
`;

export const ModalWrapperInput = styled.div`
  margin-top: 26px;
`;

export const ModalWrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;

  width: 20%;

  margin-left: auto;
  margin-top: 48px;

  @media (max-width: 480px) {
    width: 30%;
  }
`;