import { MAXIMUM_QUANTITY_ALLOWED } from 'constant'
import { createContext, useEffect, useState } from 'react'
import EventError from 'Errors/EventError'
import ExpiredCartError from 'Errors/ExpiredCartError'
import { ItemReducer } from 'helpers/array'
import { isCommissioner } from 'helpers/comissioner'
import { GtagAddToCart, GtagRemoveFromCart } from 'helpers/Payment'
import { calculateTicketValue } from 'helpers/tickets'
import { toast } from 'react-toastify'
import { generateCartItem, getCart, postAddCart, postRemoveCart } from 'services/cart/requests'
import { CartItems, CartProps, IPromotionLotsInfo } from 'services/cart/types'
import { GetEventInLocalStorage } from 'services/eventTicket/helper'
import { clearSendPixel } from 'services/Facebook'
import { PaymentStatus } from 'services/payments/interface'
import { cancelPreferenceId } from 'services/payments/requests'
import { getSessionId } from 'services/sessionId/requests'
import { ErrorMessageAddToCart } from 'utils/enums'
import { AddToCartProps, CartContextData, CartProviderProps, HandleCalculateCartProps,  ProtectionProps, RemoveFromCartProps, TableDataProps, ValitadeCartQuantityProps} from './types'

export const CartContext = createContext({} as CartContextData)

const PaymentStatusToClearCart = [
  PaymentStatus.APPROVED,
  PaymentStatus.AUTHORIZED,
  PaymentStatus.IN_PROCESS,
  PaymentStatus.PENDING,
]

export function CartProvider({ children }: CartProviderProps) {
  const [loading, setLoading] = useState(false)
  const [globalCart, setGlobalCart] = useState<CartProps>({} as CartProps)
  const [tablesData, setTablesData] = useState<TableDataProps[]>([])
  const [itemToRemoveUsingModal, setItemToRemoveUsingModal] = useState<CartItems | undefined>(undefined)
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const checkoutGateway = globalCart?.checkoutGateway
  const showYellowButton = globalCart?.showYellowButton
  const creditCardPaymentMethod = globalCart?.creditCardPaymentMethod
  const pixPaymentMethod = globalCart?.pixPaymentMethod
  const multiplePaymentMethod = globalCart?.multiplePaymentMethod
  const incompleteTables = tablesData.filter(table => table.min > table.quantity && !table.remanescente && table.open == true)
  const habilitarCarrinho = globalCart?.items?.length > 0 && incompleteTables.length == 0
  const [isNeedChooseRefundOption, setIsNeedChooseRefundOption] = useState<boolean>(false)

  const quantityItemInCart =
    ItemReducer({
      items: globalCart?.items,
      keyValue: 'quantity',
    }) || 0

  const identificationPage =
    ItemReducer({
      items: globalCart?.items,
      keyValue: 'ticketByCpf',
    }) || 0

  const setLocalCart = (cart: CartProps) => {
    if(typeof window !== "undefined"){
      window.localStorage.setItem('cart', JSON.stringify(cart))
    }
  }

  const setCartToPedingCheckout = (cart: CartProps) => {
    if(typeof window !== "undefined"){
      window.localStorage.setItem('cart', JSON.stringify(cart))
    }
    setGlobalCart(cart);
  }

  const setPreferenceIdMP = (preferenceId: string) => {
    if(typeof window !== "undefined"){
      window.localStorage.setItem('preference_id_mp', preferenceId)
    }
  }

  const getPreferenceIdMP = (): string | null => {
    return typeof window !== "undefined" ? window.localStorage.getItem('preference_id_mp') : null
  }

  const removePreferenceIdMP = (): void => {
    if(typeof window !== "undefined"){
      window.localStorage.removeItem("preference_id_mp")
    }
  }

  const cancelTicketWithPreferenceId = (): void => {
    const preferenceId = getPreferenceIdMP()
    if (preferenceId) cancelPreferenceId({ preferenceId })
  }

  const getOnlineCart = async (): Promise<CartProps> => {
    try {
      const sessionId = await getSessionId()
      if (sessionId) {
        const cart = await getCart(sessionId)
        setLocalCart(cart)
        return cart
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
    return {} as CartProps
  }

  const getLocalCart = (): CartProps => {
    const jsonCart = typeof window !== "undefined" ? window.localStorage.getItem('cart') : null
    if (jsonCart == null) {
      return {} as CartProps
    }
    return JSON.parse(jsonCart)
  }

  useEffect(() => {
    const cart = getLocalCart()
    if (cart != null) {
      setGlobalCart(cart)
    } else {
      setGlobalCart({} as CartProps)
    }
  }, [window.localStorage.getItem('cart')])

  /**
   * Clears the cart by removing all relevant items from local and session storage.
   * Also resets the global and local cart objects and clears any pixel tracking.
   */
  const clearCart = () => {
    if(typeof window !== "undefined"){
      window.localStorage.removeItem('sessionId')
      window.localStorage.removeItem('cart')
      window.localStorage.removeItem('identificationTicket')
      window.localStorage.removeItem('EventInCart')
    }

    sessionStorage.removeItem('seatsio')
    removePreferenceIdMP()
    setGlobalCart({} as CartProps)
    setLocalCart({} as CartProps)
    clearSendPixel()
  }

  const clearCartByPaymentStatus = (status: PaymentStatus | undefined) => {
    if (!status) return
    if (PaymentStatusToClearCart.includes(status)) clearCart()
  }

  const checkEventId = (localEventId: number) => {
    if (globalCart?.items?.[0]?.eventId && localEventId !== globalCart?.items?.[0]?.eventId) {
      throw new EventError(ErrorMessageAddToCart.DIFFERENT_EVENT)
    }
  }

  const [subTotal, setSubTotal] = useState(0)
  const [total, setTotal] = useState(0)
  const [quantity, setQuantity] = useState(0)
  const [tax, setTax] = useState(0)
  const [processingFee, setProcessingFee] = useState(0)
  const [protection, setProtection] = useState<ProtectionProps>({
    provider: undefined,
    quoteId: '',
    value: 0,
  })

  const getTotalDiscountValueByPromo = () => {
    return globalCart?.items?.reduce((acc, currentItem) => acc + (currentItem?.discountValue || 0) * currentItem?.quantity, 0)
  }

  const handleCalculateCart = () : HandleCalculateCartProps => {
    let calculatedSubTotal = 0
    let calculatedTotal = 0
    let calculatedQuantity = 0
    let calculatedTax = 0
    let calcultedProcessingFee = 0

    globalCart?.items?.forEach(ticket => {
      const ticketValue = calculateTicketValue({
        ticketValue: ticket?.value,
        promoTicket: ticket?.promoTicket,
        promoTicketQuantity: ticket?.promoTicketQuantity,
      })

      if (ticket?.LotProcessingFeeAdd === 0 && ticket?.LotProcessingFeeValue) {
        calcultedProcessingFee += ticket?.LotProcessingFeeValue * ticket.quantity
      }

      const subTotalWithPromoTicket = ticketValue * (ticket.quantity / ticket.promoTicketQuantity)
      const subtTotalWithoutPromoTicket = ticketValue * ticket.quantity

      calculatedSubTotal += ticket?.promoTicket ? subTotalWithPromoTicket : subtTotalWithoutPromoTicket

      calculatedTax += ticket.taxTotal * ticket.quantity
      calculatedQuantity += ticket.quantity
    })

    calculatedTotal =
      calculatedSubTotal + calculatedTax + calcultedProcessingFee + protection.value - getTotalDiscountValueByPromo()

    setSubTotal(calculatedSubTotal)
    setTotal(calculatedTotal)
    setQuantity(calculatedQuantity)
    setTax(calculatedTax)
    setProcessingFee(calcultedProcessingFee)

    return {
      calculatedSubTotal,
      calculatedTotal,
      calculatedQuantity,
      calculatedTax,
      calcultedProcessingFee
    }
  }

  useEffect(() => {
    handleCalculateCart()
  }, [globalCart, protection.value])

  const removeFromCart = async (data: RemoveFromCartProps) => {
    cancelTicketWithPreferenceId()
    removePreferenceIdMP()
    setLoading(true)

    try {
      const sessionId = await getSessionId()

      if (sessionId) {
        const functioncart = await postRemoveCart({
          sessionId,
          ...data,
        })

        if (!functioncart) {
          throw new Error('Carrinho não encontrado')
        }

        const event = GetEventInLocalStorage()
        const lastTicketRemoved = generateCartItem(event, {
          Id: 0,
          Quantity: data.quantity,
          LotId: data.lotId,
          ControlledPlaceId: data.controlledPlaceId,
        })

        GtagRemoveFromCart(lastTicketRemoved)

        if (functioncart?.items?.length == 0) {
          clearCart()
        } else {
          setLocalCart(functioncart)
        }
      }
    } catch (error: any) {
      setLoading(false)

      if (error?.status == 404) {
        clearCart()
        toast.error('Carrinho expirado')
        throw new ExpiredCartError('Carrinho expirado')
      } else {
        toast.error(error)
        throw new Error(error)
      }
    } finally {
      setLoading(false)
    }
  }

  function validateCartQuantity (data: AddToCartProps): ValitadeCartQuantityProps {
    const localcart = getLocalCart()
    const quantityInCart = localcart?.items?.reduce((acc, item) => acc   + item.quantity, 0) || 0
    const event = GetEventInLocalStorage()

    const filteredSections = event?.sections?.filter((section) =>
      section?.tickets?.some((ticket) => ticket?.lotId === data?.lotId)
    );

    const maxQuantity = filteredSections[0]?.bookAsAWhole && filteredSections[0]?.maxQtd > MAXIMUM_QUANTITY_ALLOWED ? filteredSections[0]?.maxQtd : MAXIMUM_QUANTITY_ALLOWED
   
    return {
      isValid : data.quantity + quantityInCart <= maxQuantity,
      maxQuantity: maxQuantity
    }
  }

  async function addToCart(data: AddToCartProps): Promise<void> {
    cancelTicketWithPreferenceId()
    removePreferenceIdMP()
    setLoading(true)
    try {
      const validate = validateCartQuantity(data);
      if ( !validate.isValid ) {
        throw new Error(`É possível adicionar somente ${validate.maxQuantity} ingressos por vez em seu carrinho, finalize essa compra e adquira mais ingressos em uma próxima.`)
      }

      const sessionId = await getSessionId()
      const functioncart = await postAddCart({
        sessionId,
        ...data,
      })

      if (!functioncart) {
        throw new Error('Carrinho não encontrado')
      }

      const event = GetEventInLocalStorage()
      const lastItemAdded = generateCartItem(event, {
        Id: 0,
        Quantity: data.quantity,
        LotId: data.lotId,
        ControlledPlaceId: data.controlledPlaceId,
      })

      GtagAddToCart(lastItemAdded)
      setLocalCart(functioncart)
    } catch (error: any) {
      const message = error?.data?.Message || error?.message
      toast.error(message)
      throw error
    } finally {
      setLoading(false)
    }
  }

  const handleGetQtdItemInCart = (ticketId: number, controlledPlaceId?: number): number => {
    if (globalCart?.items?.length) {
      if (ticketId && controlledPlaceId) {
        const placeInfo = globalCart?.items?.find(
          ticket => ticket?.ticketId === ticketId && ticket?.coordinates?.[0]?.controlledPlaceId === controlledPlaceId,
        )

        if (placeInfo) return placeInfo?.quantity
        else return 0
      } else if (ticketId) {
        const ticketInfo = globalCart?.items?.find(ticket => ticket?.ticketId === ticketId)
        if (ticketInfo) return ticketInfo?.quantity
        else return 0
      } else {
        return 0
      }
    } else {
      return 0
    }
  }

  useEffect(() => {
    const expireDate = new Date(globalCart.exp)
    if (globalCart.exp && new Date() > expireDate) {
      clearCart()
      toast.error('Carrinho expirado')
    }
  }, [globalCart])

  const getCartItemByControlledPlaceId = (controlledPlaceId: number): CartItems | undefined => {
    return globalCart?.items?.find(item => item?.coordinates?.[0]?.controlledPlaceId === controlledPlaceId)
  }

  const getCartItemByLotId = (lotId: number): CartItems | undefined => {
    return globalCart?.items?.find(item => item?.lotId === lotId)
  }

  const getSelectedSeatsTable = (): TableDataProps[] => {
    try {
      const tables = globalCart?.items?.map(item => item?.coordinates?.[0]?.SeatsTable)
      const tablesWithoutUndefined = tables?.filter(item => item != undefined)
      const event = GetEventInLocalStorage()

      var unique = tablesWithoutUndefined?.filter((value, index, array) => {
        return array?.indexOf(value) === index
      })

      const tableData = unique?.map(table => {
        const itemTable = globalCart?.items?.find(item => item?.coordinates?.[0]?.SeatsTable == table)
        const seatsInTable = tables?.filter(item => item == table).length

        const tablesection = event?.sections?.find(section => {
          const tabletickets = section?.tickets?.find(tableticket => tableticket?.lotId == itemTable?.lotId)

          return tabletickets != undefined
        })

        const remanescenteChairArray = tablesection?.controlledPlaces?.filter(
          x => x.SeatsTable == table && x.status == 'Remanescente',
        )
        const remanscente = remanescenteChairArray != undefined ? remanescenteChairArray.length > 0 : false

        return {
          label: table,
          quantity: seatsInTable,
          min: tablesection?.minQtd || 1,
          max: tablesection?.maxQtd,
          open: tablesection?.minQtd != tablesection?.maxQtd,
          sectorName: tablesection?.name || '',
          remanescente: remanscente,
        }
      })

      return tableData || []
    } catch (ex) {}

    return []
  }

  function isChoosedRefundOption(): boolean {
    const eventinfo = GetEventInLocalStorage()
    const refundObject = !isCommissioner() ? eventinfo?.refundServiceSite : eventinfo?.refundServiceComissioner
    const valid =
      refundObject === undefined || refundObject === null || (refundObject?.IsActive == 1 && protection.provider != undefined)
    return valid
  }

  useEffect(() => {
    setTablesData(getSelectedSeatsTable())
  }, [globalCart])

  function validateProtection(): boolean {
    const eventinfo = GetEventInLocalStorage()
    const refundObject = !isCommissioner() ? eventinfo?.refundServiceSite : eventinfo?.refundServiceComissioner
    const valid =
      refundObject === undefined || refundObject === null || (refundObject?.IsActive == 1 && protection.provider != undefined)
    if (!valid) {
      toast.error('Uma seleção obrigatória não foi preenchida.')
    }

    return valid
  }

  const updateCartWithPromo = (
    lotsWithPromo: IPromotionLotsInfo[],
    promoCode: string,
    promoCodeId: number,
    quantityAllowed: number,
  ) => {
    if (quantityAllowed <= 0) return
    const currentGlobalCart = globalCart
    const newItemsToGlobalCart: CartItems[] = []
    currentGlobalCart?.items?.forEach(item => {
      const lotPromo = lotsWithPromo?.find(lot => lot.LotId === item.lotId)
      if (lotPromo && quantityAllowed > 0) {
        if (item.quantity <= quantityAllowed) {
          newItemsToGlobalCart.push({
            ...item,
            promotionCodeId: promoCodeId,
            promoCode: promoCode,
            valuePromo: item.value - lotPromo.ValuesWithDiscount.DiscountValue,
            discountValue: lotPromo.ValuesWithDiscount.DiscountValue,
            quantityCuponsApplied: item.quantity,
            taxTotal: lotPromo.ValuesWithDiscount?.TaxTotal,
            LotProcessingFeeValue: lotPromo.ValuesWithDiscount?.LotProcessingFeeValue,
            valueRefundServiceSite: lotPromo.ValuesWithDiscount?.RefundableSiteValue,
          })
          quantityAllowed = quantityAllowed - item.quantity
        } else if (item.quantity > quantityAllowed) {
          newItemsToGlobalCart.push({
            ...item,
            valuePromo: item.value - lotPromo.ValuesWithDiscount.DiscountValue,
            discountValue: lotPromo.ValuesWithDiscount.DiscountValue,
            promotionCodeId: promoCodeId,
            promoCode: promoCode,
            quantityCuponsApplied: quantityAllowed,
            taxTotal: lotPromo.ValuesWithDiscount?.TaxTotal,
            LotProcessingFeeValue: lotPromo.ValuesWithDiscount?.LotProcessingFeeValue,
            valueRefundServiceSite: lotPromo.ValuesWithDiscount?.RefundableSiteValue,
            quantity: quantityAllowed,
          })
          newItemsToGlobalCart.push({
            ...item,
            quantity: item.quantity - quantityAllowed,
          })
          quantityAllowed = quantityAllowed - quantityAllowed
        } else {
          newItemsToGlobalCart.push(item)
        }
      } else {
        newItemsToGlobalCart.push(item)
      }
    })
    setGlobalCart({
      ...currentGlobalCart,
      items: newItemsToGlobalCart,
    })
  }

  return (
    <CartContext.Provider
      value={{
        handleCalculateCart,
        checkoutGateway,
        clearCart,
        loading,
        getOnlineCart,
        cart: globalCart,
        habilitarCarrinho,
        quantityItemInCart,
        getLocalCart,
        checkEventId,
        subTotal,
        total,
        quantity,
        totalTax: tax,
        totalProcessingFee: processingFee,
        addToCart,
        handleGetQtdItemInCart,
        removeFromCart,
        identificationPage,
        setPreferenceIdMP,
        getPreferenceIdMP,
        showYellowButton,
        clearCartByPaymentStatus,
        getCartItemByControlledPlaceId,
        getCartItemByLotId,
        protection,
        setProtection,
        tablesData,
        incompleteTables,
        creditCardPaymentMethod,
        pixPaymentMethod,
        multiplePaymentMethod,
        setCartToPedingCheckout,
        itemToRemoveUsingModal,
        setItemToRemoveUsingModal,
        errorMessage,
        setErrorMessage,
        isChoosedRefundOption,
        isNeedChooseRefundOption,
        setIsNeedChooseRefundOption,
        validateProtection,
        updateCartWithPromo,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}

export default CartProvider
