import styled from "styled-components";
import { ButtonProps, InputRegisterFormProps } from "./types";
import { WrapperStrongPasswordProps } from "../types";

export const WrapperPassword = styled.div`
  position: relative;
`;

export const WrapperStrongPassword = styled.div<WrapperStrongPasswordProps>`
  display: ${(props) => (props.show ? "block" : "none")};
  position: absolute;
  z-index: 3;
  background-color: #fff;
  left: -330px;
  top: 24px;
  transform: translateY(-50%);
`;

export const SideArrow = styled.div`
  color: #fff;

  font-size: 40px;
  transform: rotate(90deg);

  right: -32px;
  position: absolute;
  top: calc(50% - 24px);
  z-index: 2;
  text-shadow: 0 -3px 6px rgba(0, 0, 0, 0.05);
`;

export const InputRegisterForm = styled.input<InputRegisterFormProps>`
  border: ${(props) =>
    props.error ? "1px solid #ff6961" : "1px solid #E1E1E1"};
  border-radius: 8px;
  padding: 12px 16px;
  color: #a3a3a3;
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
`;

export const CaptchaAndButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 1.6rem;
  margin-top: 1.6rem;
`;

export const RegisterButton = styled.button.attrs({
  type: "submit",
})<ButtonProps>`
  width: 100%;

  display: block;
  height: 4.8rem;
  border-radius: 0.8rem;
  font-weight: 600;
  font-size: 1.6rem;
  transition: all 0.3s;
  color: var(--white);

  margin-top: 1rem;

  cursor: ${(props) => (props.disabled ? " not-allowed" : "pointer")};
  background: ${(props) =>
    props.disabled ? "var(--gray200)" : "var(--orange)"};

  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const ErrorMessage = styled.span`
  font-size: 1.4rem;
  color: #ff6961;
`;

export const ErrorIcon = styled.img`
  margin-right: 0.8rem;
`;

export const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;

  margin-top: 0.8rem;
  margin-bottom: 24px;
`;
