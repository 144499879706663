import * as S from "./styles";
import { InputNotControled } from "components/Form/InputNotControled";

import { cpfMaskFromString } from "helpers/maks/masks";
import {
    closeModalImg,
    emailErrorImg,
    emailImg,
} from "assets/img";
  
import Modal from "components/NewModal";
import { useState } from "react";
import { USER_NOT_FOUND } from "constant";
import { validateCpf, validateEmail } from "helpers/validators";

import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { recoveryPassword } from "services/recoverPassword";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "components/Button";

interface Props {
    isOpen: boolean,
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}
const RecoverPassword = ({isOpen, setIsOpen}:Props) => {
    const [recoveryValue, setRecoveryValue] = useState("");
    const [isRecoveryLoading, setIsRecoveryLoading] = useState(false);
    const [recoveryPasswordResponse, setRecoveryPasswordResponse] = useState("");

    const IS_VALID_CPF = validateCpf(recoveryValue);
    const IS_VALID_EMAIL = validateEmail(recoveryValue);
    const IS_WRONG_PASSWORD_VALUE =
        !!recoveryValue && !(IS_VALID_CPF || IS_VALID_EMAIL);

    const IS_USER_NOT_FOUND = recoveryPasswordResponse === USER_NOT_FOUND;
    const RECOVERY_MODAL_TITLE = IS_USER_NOT_FOUND
        ? "Documento não encontrado"
        : "Recuperar senha";

    const disableButton = 
        recoveryPasswordResponse
        ? false
        : !IS_VALID_CPF && !IS_VALID_EMAIL;

    const handleRecoveryPassword = async () => {
        setIsRecoveryLoading(true);
        try {
            const identifier = IS_VALID_CPF
            ? cpfMaskFromString(recoveryValue)
            : recoveryValue;
            const response = await recoveryPassword(identifier);
    
            setRecoveryPasswordResponse(response!);
        } catch (error: any) {
            setRecoveryPasswordResponse(error.message);
        } finally {
            setIsRecoveryLoading(false);
        }
        };

    const buttonHandler = () => {
        if (!recoveryPasswordResponse) {
            handleRecoveryPassword();
        } else {
            setIsOpen(false);
            setRecoveryValue("");
            setRecoveryPasswordResponse("");
        }
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => {
                setIsOpen(false);
            setRecoveryValue("");
            setRecoveryPasswordResponse("");
            }}
        >
            <S.ModalHeader>
                <S.ModalTitle>
                    {RECOVERY_MODAL_TITLE}
                </S.ModalTitle>
                <S.ModalCloseButton
                    src={closeModalImg}
                    onClick={() => {
                        setIsOpen(false);
                    setRecoveryValue("");
                    setRecoveryPasswordResponse("");
                    }}
                />
            </S.ModalHeader>
            {!recoveryPasswordResponse ? (
                <>
                    <S.ModalDescription>
                        Informe aqui o documento que está associado a sua conta para que
                        assim possamos te enviar um e-mail de recuperação de senha.
                    </S.ModalDescription>

                    <S.ModalWrapperInput>
                        <InputNotControled
                            type="text"
                            leadingIcon={IS_WRONG_PASSWORD_VALUE ? emailErrorImg : emailImg}
                            placeholder="Insira aqui seu e-mail/CPF"
                            value={recoveryValue}
                            messageError={
                            IS_WRONG_PASSWORD_VALUE
                                ? "Informe um cpf ou e-mail válido"
                                : ""
                            }
                            onChange={(e) => {
                            setRecoveryValue(e.target.value);
                            }}
                            inputMode="text"
                            mask={IS_VALID_CPF ? "cpf" : "email"}
                        />
                    </S.ModalWrapperInput>
                </>
            ) : !IS_USER_NOT_FOUND ? (
                <>
                    <S.ModalDescription>
                        Enviamos um e-mail com as coordenadas para recuperação de senha
                        {recoveryPasswordResponse !== "email_sent" ? (
                            <>
                            {" "}
                            no seguinte e-mail:{" "}
                            <strong>{recoveryPasswordResponse}</strong>
                            </>
                        ) : (
                            ""
                        )}
                    </S.ModalDescription>

                    {recoveryPasswordResponse !== "email_sent" && (
                        <S.ModalDescription>
                            Acesse este e-mail e siga as instruções para recuperar sua
                            senha.
                        </S.ModalDescription>
                    )}
                </>
            ) : (
                <S.ModalDescription>
                    O documento enviado não possui nenhum cadastro em nosso site, caso
                    queira você pode tentar novamente ou ir para o nosso cadastro.
                </S.ModalDescription>
            )}

            <S.ModalWrapperButton>
                <Button
                    type="button"
                    onClick={buttonHandler}
                    disabled={disableButton}
                >
                    {isRecoveryLoading ? (
                        <FontAwesomeIcon
                            icon={faSpinner}
                            className="q2l-signIn-form__button_loading"
                            spin={true}
                        />
                    ) : (
                        ""
                    )}
                    {recoveryPasswordResponse ? "Ok" : "Enviar"}
                </Button>
            </S.ModalWrapperButton>
        </Modal>
    )
}

export default RecoverPassword;