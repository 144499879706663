import { useEffect } from "react";
import { Register } from "../../../layouts/Register";
import { SignIn } from "../../../layouts/SignIn";
import { Q2IngressosTheme } from "../../../themes/Q2IngressosTheme";
import { WhatsappButton } from "components/WhatsappButton";

export function SignInRegisterDesktop() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Q2IngressosTheme>
      <main>
        <div className="q2l-signIn-register-desktop">
          <div className="container">
            <div className="q2l-signIn-register-desktop__content">
              <section
                className="q2l-signIn-register-desktop__signIn"
                data-aos="fade-up"
              >
                <h2 className="q2l-signIn-register-desktop__title">Login</h2>
                <SignIn />
              </section>
              <section
                className="signInRegisterDesktop__register"
                data-aos="fade-up"
              >
                <h2 className="q2l-signIn-register-desktop__title">
                  Cadastrar-se
                </h2>
                <Register />
              </section>
            </div>
          </div>
        </div>
      </main>
      <WhatsappButton />
    </Q2IngressosTheme>
  );
}
