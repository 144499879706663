export enum Names {
  searchEvent = 'search'
}

export enum Options {
  FALSE = 0,
  TRUE = 1
}

export enum Gender {
  NotRegistered = 0,
  Male = 1,
  Female = 2,
  Other = 3,
  PreferNotToSay = 4
}


/**
 * Represents the types of tickets available.
 * 
 * - ticket: A regular ticket.
 * - seat: A ticket with assigned seating (open table).
 * - table: A ticket for a table reservation (closed table).
 */
export enum TicketType {
  ticket,
  seat,
  table
}