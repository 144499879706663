import styled from "styled-components";
import { ComponentProps } from "./types";

export const Wrapper = styled.div<ComponentProps>`
  padding: 12px 10px;
  font-size: ${(props) => props.size}px;
  line-height: 100%;

  @media (max-width: 768px) {
    padding-left: 2rem;
  }

  display: flex;
  align-items: center;
  ${(props) => props.center ? "justify-content: center;" : ""};
  text-align: center;

  color: #292929;
  background: #fafafa;
  border-radius: 8px;

  @media (max-width: 768px) {
    font-size: 14px;
    width: 100%;
  }
`;
export const Strong = styled.span`
  font-weight: 500;
`;
