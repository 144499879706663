import Select, { components, StylesConfig } from "react-select";
import {
  ErrorIcon,
  ErrorMessage,
  ErrorWrapper,
  TrailingIcon,
  Wrapper,
} from "./styles";
import { FormSelectProps } from "./types";
import { warningImg } from "../../../assets/img";

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <TrailingIcon />
    </components.DropdownIndicator>
  );
};

const FormSelect = ({
  value,
  placeholder,
  leadingImage,
  onChange,
  onFocus,
  options,
  error,
}: FormSelectProps) => {
  const customStyles: StylesConfig = {
    valueContainer: (styles) => ({
      ...styles,
      padding: leadingImage !== undefined ? "0 0 0 5.7rem" : "0 0 0 2.4rem",
      borderRadius: "24px",
      fontSize: "16px",
      opacity: "0.7",
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      display: "none",
    }),
    placeholder: (styles) => ({
      ...styles,
      color: error ? "#ff6961" : "var(--gray500)",
    }),
    control: (styles) =>
      leadingImage
        ? {
            ...styles,
            height: "4.8rem",
            borderWidth: "2px",
            borderStyle: "solid",
            borderColor: error ? "#ff6961" : "var(--gray200)",
            color: "#000",
            borderRadius: "0.8rem",
            transition: "all 0.3s ease-in-out",
            cursor: "pointer",
            ":focus-within": {
              borderWidth: "2px",
              borderStyle: "solid",
              borderColor: "#000",
              outline: "none",
              boxShadow: "none",
            },
            ":before": {
              position: "absolute",
              left: "33px",
              top: "50%",
              transform: "translate(-50%,-50%)",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              height: "24px",
              width: "24px",
              content: '" "',
              backgroundImage: `url('${leadingImage}')`,
            },
            ":hover": {
              borderColor: error ? "rgba(255, 105, 97, 0.5)" : "var(--gray300)",
              outline: "none",
              boxShadow: "none",
              ":focus-within": {
                borderWidth: "2px",
                borderStyle: "solid",
                borderColor: "#000",
                outline: "none",
                boxShadow: "none",
              },
            },
          }
        : {
            ...styles,
            height: "4.8rem",
            border: "2px solid var(--gray200)",
            borderRadius: "0.8rem",
          },
    indicatorsContainer: (styles, state) => ({
      ...styles,
      display: options?.length && options?.length <= 1 ? "none" : "flex",
      color: "#256DB1",
      svg: {
        height: "24px",
        width: "24px",
      },
      transition: "transform 0.5s",
      transform: state.selectProps.menuIsOpen
        ? "rotate(180deg)"
        : "rotate(0deg)",
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
    }),
    option: (styles, state) => ({
      ...styles,
      color: state.isSelected ? "var(--blue500)" : "var(--gray500)",
      backgroundColor: state.isSelected ? "var(--gray100)" : "var(--white)",
      ":hover": {
        backgroundColor: "var(--gray100)",
      },
      fontSize: "16px",
      cursor: "pointer",
    }),
  };

  return (
    <Wrapper>
      <Select
        styles={customStyles}
        value={value}
        isSearchable
        placeholder={placeholder}
        onChange={onChange}
        options={options}
        components={{ DropdownIndicator }}
        onFocus={onFocus}
      />
      <ErrorWrapper hasError={!!error}>
        <ErrorIcon src={warningImg} alt="" />
        <ErrorMessage>{error?.message}</ErrorMessage>
      </ErrorWrapper>
    </Wrapper>
  );
};

export default FormSelect;
