import styled from "styled-components";
import { backgroundcolorMap, borderColorMap, ButtonSizeMap, colorMap, StyledButtonProps } from "./interface";

export const ButtonStyle = styled.button<StyledButtonProps>`
  display: block;
  width: 100%;
  height: ${(props) => props.ButtonSize ? ButtonSizeMap[props.ButtonSize] : '4.8rem'};
  background: ${(props) => props.ButtonColor ? backgroundcolorMap[props.ButtonColor] : backgroundcolorMap.primary};
  border-radius: 0.8rem;
  font-weight: 600;
  font-size: 1.6rem;
  color: ${(props) => props.ButtonColor ? colorMap[props.ButtonColor] : colorMap.primary};
  border: ${(props) => props.ButtonColor ? borderColorMap[props.ButtonColor] : borderColorMap.primary};

  transition: all 0.3s;
  cursor: pointer;

  ${(props) => (props.disabled || props.customDisabled) && `
    background: var(--gray200);
    cursor: not-allowed;
  `}

  &:hover {
    filter: brightness(0.9);
  }
`;