import { baseURL, cdnURL } from "..";
//import { SellingPoints } from "./types";

export const getSellingPointsFromJson = async (eventData: number) => {
  try {
    const path = `assets/api/website/GetSellingPointsByEvent/${eventData}.json?h=${Date.now()}`;
    const response = await cdnURL.get(path);
    if (response.status !== 200) {
      throw new Error("Not found");
    }
    return response.data;
  } catch (error: any) {
    throw error.response;
  }
};

export const getSellingPointsFromApi = async (eventData: number) => {
  try {
    const response = await baseURL.post("api/v2/website/GetSellingPointsByEvent", {
      eventId: eventData
    });

    return response.data;
  } catch (error: any) {
    throw error.response;
  }
};

export const getSellingPoints = async (eventData: number) => {
  try {
    const data = await getSellingPointsFromJson(eventData);
    return data;
  } catch (error: any) {
  }
  try {
    const data = await getSellingPointsFromApi(eventData);
    return data;
  } catch (error: any) {
    throw error.response;
  }
};
