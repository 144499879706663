import React, { useEffect } from 'react'
import * as S from './styles'

const WhatsAppScript = () => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = '//js.hsforms.net/forms/embed/v2.js'
    script.charset = 'utf-8'
    script.onload = () => {
      ;(window as any).hbspt.forms.create({
        region: 'na1',
        portalId: '43591742',
        formId: 'be19e406-f260-4852-8e25-b4dc73811e1a',
        css: 'div {max-width: 115rem width: 100%;}',
        target: '#hubspotForm',
        onFormSubmit: () => {
          const whatsappUrl = 'https://api.whatsapp.com/send?phone=+5508002320800&text=Olá, gostaria de uma informação.'
          window.open(whatsappUrl, '_blank')
          window.location.href = '/'
        },
      })

      const checkFormCompletion = () => {
        const firstnameInput = document.querySelector('input[name="firstname"]') as HTMLInputElement
        const emailInput = document.querySelector('input[name="email"]') as HTMLInputElement
        const phoneInput = document.querySelector('input[name="phone"]') as HTMLInputElement
        const consentCheckbox = document.getElementById(
          'LEGAL_CONSENT.subscription_type_332117305-be19e406-f260-4852-8e25-b4dc73811e1a',
        ) as HTMLInputElement
        const termsCheckbox = document.getElementById(
          'LEGAL_CONSENT.subscription_type_221032813-be19e406-f260-4852-8e25-b4dc73811e1a',
        ) as HTMLInputElement

        const button = document.querySelector('.hs-button') as HTMLButtonElement
        if (
          firstnameInput &&
          emailInput &&
          phoneInput &&
          consentCheckbox &&
          termsCheckbox &&
          firstnameInput.value.trim() &&
          emailInput.value.trim() &&
          phoneInput.value.trim() &&
          consentCheckbox.checked &&
          termsCheckbox.checked
        ) {
          button.disabled = false
          button.classList.remove('is-disabled')
        } else {
          button.disabled = true
          button.classList.add('is-disabled')
        }
      }
      //Utilizei um observer para verificar se o formulário foi carregado e prevenir erros com timeouts
     const observer = new MutationObserver(() => {
       const formLoaded = document.querySelector('input[name="firstname"]')
       if (formLoaded) {
         observer.disconnect() // Para de observar mudanças no DOM quando os elementos forem encontrados

         // Adiciona os eventos de input e change para verificar o formulário
         const firstnameInput = document.querySelector('input[name="firstname"]') as HTMLInputElement
         const emailInput = document.querySelector('input[name="email"]') as HTMLInputElement
         const phoneInput = document.querySelector('input[name="phone"]') as HTMLInputElement
         const consentCheckbox = document.getElementById(
           'LEGAL_CONSENT.subscription_type_16105464-21703e1d-7a29-4b6e-81ef-2a623956fd90',
         ) as HTMLInputElement
         const termsCheckbox = document.getElementById(
           'LEGAL_CONSENT.processing-21703e1d-7a29-4b6e-81ef-2a623956fd90',
         ) as HTMLInputElement

         if (firstnameInput)
          {
            firstnameInput.addEventListener('input', checkFormCompletion)
            firstnameInput.addEventListener('change', checkFormCompletion)
          }
         if (emailInput)
          { 
          emailInput.addEventListener('input', checkFormCompletion) 
          emailInput.addEventListener('change', checkFormCompletion)
          }
         if (phoneInput) {
           phoneInput.addEventListener('input', checkFormCompletion)
           phoneInput.addEventListener('change', checkFormCompletion)
         }
         
         if (consentCheckbox) consentCheckbox.addEventListener('change', checkFormCompletion)
         if (termsCheckbox) termsCheckbox.addEventListener('change', checkFormCompletion)

         checkFormCompletion()
       }
     })

      observer.observe(document.body, { childList: true, subtree: true })
    }

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return <S.WhatsAppContainerForm id="hubspotForm" className="container"></S.WhatsAppContainerForm>
}

export default WhatsAppScript
